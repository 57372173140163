<template>
	<div class="marker">
		<div v-if="count > 0" class="marker__count">{{ count }}</div>
		<ShipPinIcon v-if="isShip" />
		<TruckPinIcon v-else />
	</div>
</template>

<script>
import ShipPinIcon from '@/components/shared/Icons/MapIcons/ShipPinIcon.vue';
import TruckPinIcon from '@/components/shared/Icons/MapIcons/TruckPinIcon.vue';
export default {
	name: 'MapMarker',
	components: {
		ShipPinIcon,
		TruckPinIcon,
	},
	props: {
		count: {
			type: Number,
			default: 0,
		},
		isShip: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {};
	},
	computed: {},
};
</script>

<style lang="scss">
@import 'MapMarker.scss';
</style>
