import { i18n } from '@/main.js';

export default function getStatusTranslation(status) {
	const translate = i18n.t.bind(i18n);

	return {
		'EM PROCESSAMENTO': translate('contract_step.processing'),
		'RESTRIÇÕES CLIENTE': translate('contract_step.released_customer_restriction'),
		'EM PRODUÇÃO': translate('contract_step.in_production'),
		'EM CARREGAMENTO': translate('contract_step.in_loading'),
		'GATE IN ORIGEM': translate('contract_step.gate_in'),
		'AGUARDA PRÉ PGTO': translate('contract_step.pre_payment_pending'),
		EMBARCADO: translate('contract_step.boarded'),
		'DOCUMENTAÇÃO ENVIADA': translate('contract_step.documentation_sended'),
		'DOCUMENTAÇÃO ENTREGUE': translate('contract_step.documentation_delivered'),
		'ENTREGUE NO POD': translate('contract_step.delivered_in_pod'),
		'GATE OUT DESTINO': translate('contract_step.gate_out'),
		'VAZIO DEVOLVIDO': translate('contract_step.empty_return'),
		ABERTA: translate('contract_step.opened'),
		'ABERTA EM ATRASO': translate('contract_step.opened_delay'),
		'PAGO PARCIAL': translate('contract_step.partial_paid'),
		'PAGO TOTAL': translate('contract_step.total_paid'),
		FINALIZADO: translate('contract_step.finished'),
	}[status]?.toUpperCase();
}
