<template>
	<div id="load-management" class="load-management">
		<Content>
			<template v-slot:header>
				<div class="company-row">
					<div style="width: 200px">
						<CompanyInput ref="companiesHeader" :is-web="isWeb" />
					</div>
					<div class="company-row-tabs">
						<div
							class="company-row-tabs-item"
							:class="{ 'company-row-tabs-active-truck': activeTab === 'truck' }"
							@click="setActiveTab('truck')"
						>
							<TruckIcon :color="truckColor" height="15" width="15" />
							<span>{{ $t('modules.load_management.header.truck') }}</span>
						</div>
						<div
							class="company-row-tabs-item"
							:class="{ 'company-row-tabs-active-ship': activeTab === 'ship' }"
							@click="setActiveTab('ship')"
						>
							<ShipIcon :color="shipColor" height="15" width="15" />
							<span>{{ $t('modules.load_management.header.ship') }}</span>
						</div>
					</div>
				</div>
				<MobileFilter v-if="!isWeb" :fullWidth="true">
					<template v-slot:inputs-list>
						<FilterList ref="filterManagement" :isWeb="isWeb" @refreshFilters="refreshFilters" />
					</template>
				</MobileFilter>
				<WebFilter v-else>
					<template v-slot:inputs-list>
						<FilterList ref="filterManagement" :isWeb="isWeb" @refreshFilters="refreshFilters" />
					</template>
				</WebFilter>
			</template>
			<template v-slot:body>
				<div class="load-management__body">
					<MapViewer ref="mapViewer" :isWeb="isWeb" :is-ship="activeTab === 'ship'" />
					<draggable
						class="load-management__body--drag"
						v-bind="dragOptions"
						:list="sectionList"
						@end="saveOrder"
						:filter="'.button_see-more'"
					>
						<transition-group type="transition" :name="!drag ? 'flip-list' : null">
							<template v-for="(section, index) in sectionList">
								<SectionRow
									:key="index"
									:isWeb="isWeb"
									:status="section.status"
									:title="section.title"
								>
									<LoadRow
										slot="body"
										v-for="item in section.items"
										:key="item.loadNumber"
										:cargo="item"
										:isWeb="isWeb"
									/>
									<DefaultLoader slot="body" :dataStatus="section.status" />
									<button
										v-if="section.status === 'success' && section.max >= section.page"
										slot="action"
										type="button"
										class="button_see-more"
										@click="seeMore(section.getSelector)"
									>
										{{ $t('buttons.seeMore') }}
									</button>
								</SectionRow>
							</template>
						</transition-group>
					</draggable>
				</div>
			</template>
		</Content>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
	CONTRACTS_FILTER_REQUEST,
	PO_FILTER_REQUEST,
	LOAD_FILTER_REQUEST,
	TRANSPORT_FILTER_REQUEST,
	CONTAINER_FILTER_REQUEST,
	PRODUCT_FILTER_REQUEST,
} from '@/store/modules/filtersByModules/loadManagementFilters/actions';
import {
	CARGO_IN_PRODUCTION_REQUEST,
	CARGO_IN_LOADING_REQUEST,
	CARGO_GATE_IN_REQUEST,
	CARGO_SHIPPED_CARGO_REQUEST,
	CARGO_DELIVERED_REQUEST,
	CARGO_GAT_OUT_REQUEST,
	CLEAR_CARGO_MANAGEMENT_STATE,
} from '@/store/modules/loadManagement/actions';
import Content from '@/components/shared/Content/Content.vue';
import MobileFilter from '@/components/shared/MobileFilter/MobileFilter.vue';
import WebFilter from '@/components/shared/WebFilter/WebFilter.vue';
import FilterList from '@/components/modules/LoadManagement/Filter/FilterList.vue';
import SectionRow from '@/components/modules/LoadManagement/SectionRow/SectionRow.vue';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';
import MapViewer from '@/components/modules/LoadManagement/MapViewer/MapViewer.vue';
import DefaultLoader from '@/components/shared/DefaultLoader/DefaultLoader.vue';
import LoadRow from '@/components/modules/LoadManagement/LoadRow/LoadRow.vue';
import draggable from 'vuedraggable';
import CompanyInput from '@/components/shared/Header/Inputs/CompanyInput/CompanyInput.vue';
import TruckIcon from '@/components/shared/Icons/MapIcons/TruckIcon.vue';
import ShipIcon from '@/components/shared/Icons/MapIcons/ShipIcon.vue';

export default {
	name: 'LoadManagement',
	components: {
		Content,
		MobileFilter,
		WebFilter,
		FilterList,
		SectionRow,
		SpinnerIcon,
		ArrowDown,
		MapViewer,
		DefaultLoader,
		LoadRow,
		draggable,
		CompanyInput,
		TruckIcon,
		ShipIcon,
	},
	data: () => ({
		windowWidth: window.innerWidth,
		productionPage: 1,
		inLoadPage: 1,
		gateInPage: 1,
		shippedPage: 1,
		deliveredPage: 1,
		gateOutPage: 1,
		currentFilterContracts: '',
		currentPurchaseOrder: '',
		currentLoad: '',
		currentTransport: '',
		currentContainer: '',
		currentProduct: '',
		sectionList: [],
		dragging: false,
		drag: false,
		activeTab: 'ship',
	}),
	created() {
		this.refreshFilters();
		this.refreshSectionList();
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
		this.callAllTypesOfLoad();
	},
	beforeMount() {
		this.eraseState();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		...mapActions('loadManagementFilters', {
			getCompanyFilter: CONTRACTS_FILTER_REQUEST,
			getPoFilter: PO_FILTER_REQUEST,
			getLoadsFilter: LOAD_FILTER_REQUEST,
			getTransportFilter: TRANSPORT_FILTER_REQUEST,
			getContainerFilter: CONTAINER_FILTER_REQUEST,
			getProductFilter: PRODUCT_FILTER_REQUEST,
		}),
		...mapActions('loadManagement', {
			getProductionCargo: CARGO_IN_PRODUCTION_REQUEST,
			getInLoadingCargo: CARGO_IN_LOADING_REQUEST,
			getGateInCargo: CARGO_GATE_IN_REQUEST,
			getShippedCargo: CARGO_SHIPPED_CARGO_REQUEST,
			getDeliveredCargo: CARGO_DELIVERED_REQUEST,
			getGateOutCargo: CARGO_GAT_OUT_REQUEST,
			eraseState: CLEAR_CARGO_MANAGEMENT_STATE,
		}),
		// UTIL
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		setActiveTab(tab) {
			this.activeTab = tab;
		},
		// SECTIONS DRAGGABLE METHODS
		refreshSectionList() {
			this.sectionList = this.defaultSectionList;
			this.checkAndSortSectionsOrder();
		},
		checkAndSortSectionsOrder() {
			const savedOrder = localStorage.getItem('sectionOrder');

			if (savedOrder) {
				try {
					const savedOrderArray = JSON.parse(savedOrder);

					this.sectionList.sort((a, b) => {
						return savedOrderArray.indexOf(a.title) - savedOrderArray.indexOf(b.title);
					});
				} catch (error) {
					console.error('Erro ao processar a ordem salva no localStorage:', error);
				}
			}
		},
		saveOrder() {
			const currentOrder = this.sectionList.map((section) => section.title);
			localStorage.setItem('sectionOrder', JSON.stringify(currentOrder));
		},

		// API METHODS
		refreshFilters(param) {
			const filterObject = { vm: this };

			if (param != undefined && param != null) {
				filterObject['params'] = param.queryString;
				filterObject['skip'] = param.skip;
			} else {
				filterObject['params'] = null;
				filterObject['skip'] = false;
				filterObject['lastQuery'] = this.lastQueryString;
			}
			this.getCompanyFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterManagement.setCachedContractInput();
				}
			});
			this.getPoFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterManagement.setCachedPoInput();
				}
			});
			this.getLoadsFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterManagement.setCachedLoadInput();
				}
			});
			this.getTransportFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterManagement.setCachedTransportInput();
				}
			});
			this.getContainerFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterManagement.setCachedContainerInput();
				}
			});
			this.getProductFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterManagement.setCachedProductInput();
				}
			});
		},
		normalizeFilters() {
			this.currentFilterContracts = '';
			this.contractsFilterCache.value.forEach((item) => {
				this.currentFilterContracts = item.id + ',' + this.currentFilterContracts;
			});

			this.currentPurchaseOrder = '';
			this.poFilterCache.value.forEach((item) => {
				this.currentPurchaseOrder = item.id + ',' + this.currentPurchaseOrder;
			});

			this.currentLoad = '';
			this.loadFilterCache.value.forEach((item) => {
				this.currentLoad = item.id + ',' + this.currentLoad;
			});

			this.currentTransport = '';
			this.transportFilterCache.value.forEach((item) => {
				this.currentTransport = item.id + ',' + this.currentTransport;
			});

			this.currentContainer = '';
			this.containerFilterCache.value.forEach((item) => {
				this.currentContainer = item.id + ',' + this.currentContainer;
			});

			this.currentProduct = '';
			if (this.productFilterCache.value) {
				this.currentProduct = this.productFilterCache.value.id || '';
			}
		},
		getFiltered(value) {
			let queryString = '';

			value.forEach((item) => {
				queryString = item.id + ',' + queryString;
			});

			return queryString;
		},
		callAllTypesOfLoad() {
			this.normalizeFilters();
			this.getProductionCargo({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				contractNumberSequence: this.currentFilterContracts,
				purchaseOrder: this.currentPurchaseOrder,
				loadNumber: this.currentLoad,
				vessel: this.currentTransport,
				container: this.currentContainer,
				product: this.currentProduct,
				expectedEtbDate: this.etsFilterCache.value.id,
				realAtbDate: this.etaFilterCache.value.id,
				page: this.productionPage,
				vehicleType: this.activeTab,
			});
			this.getInLoadingCargo({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				contractNumberSequence: this.currentFilterContracts,
				purchaseOrder: this.currentPurchaseOrder,
				loadNumber: this.currentLoad,
				vessel: this.currentTransport,
				container: this.currentContainer,
				product: this.currentProduct,
				expectedEtbDate: this.etsFilterCache.value.id,
				realAtbDate: this.etaFilterCache.value.id,
				page: this.inLoadPage,
				vehicleType: this.activeTab,
			});
			this.getGateInCargo({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				contractNumberSequence: this.currentFilterContracts,
				purchaseOrder: this.currentPurchaseOrder,
				loadNumber: this.currentLoad,
				vessel: this.currentTransport,
				container: this.currentContainer,
				product: this.currentProduct,
				expectedEtbDate: this.etsFilterCache.value.id,
				realAtbDate: this.etaFilterCache.value.id,
				page: this.gateInPage,
				vehicleType: this.activeTab,
			});
			this.getShippedCargo({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				contractNumberSequence: this.currentFilterContracts,
				purchaseOrder: this.currentPurchaseOrder,
				loadNumber: this.currentLoad,
				vessel: this.currentTransport,
				container: this.currentContainer,
				product: this.currentProduct,
				expectedEtbDate: this.etsFilterCache.value.id,
				realAtbDate: this.etaFilterCache.value.id,
				page: this.shippedPage,
				vehicleType: this.activeTab,
			});
			this.getDeliveredCargo({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				contractNumberSequence: this.currentFilterContracts,
				purchaseOrder: this.currentPurchaseOrder,
				loadNumber: this.currentLoad,
				vessel: this.currentTransport,
				container: this.currentContainer,
				product: this.currentProduct,
				expectedEtbDate: this.etsFilterCache.value.id,
				realAtbDate: this.etaFilterCache.value.id,
				page: this.deliveredPage,
				vehicleType: this.activeTab,
			});
			this.getGateOutCargo({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				contractNumberSequence: this.currentFilterContracts,
				purchaseOrder: this.currentPurchaseOrder,
				loadNumber: this.currentLoad,
				vessel: this.currentTransport,
				container: this.currentContainer,
				product: this.currentProduct,
				expectedEtbDate: this.etsFilterCache.value.id,
				realAtbDate: this.etaFilterCache.value.id,
				page: this.gateOutPage,
				vehicleType: this.activeTab,
			});
		},
		resetAllPages() {
			this.productionPage = 1;
			this.inLoadPage = 1;
			this.gateInPage = 1;
			this.shippedPage = 1;
			this.deliveredPage = 1;
			this.gateOutPage = 1;
		},
		seeMore(value) {
			switch (value) {
				case 'productionPage':
					this.getProductionCargo({
						vm: this,
						companyId: this.headerCompanyFilterCache.value.id,
						contractNumberSequence: this.currentFilterContracts,
						purchaseOrder: this.currentPurchaseOrder,
						loadNumber: this.currentLoad,
						vessel: this.currentTransport,
						container: this.currentContainer,
						product: this.currentProduct,
						expectedEtbDate: this.etsFilterCache.value.id,
						realAtbDate: this.etaFilterCache.value.id,
						page: this.productionPage,
					});
					break;

				case 'inLoadPage':
					this.getInLoadingCargo({
						vm: this,
						companyId: this.headerCompanyFilterCache.value.id,
						contractNumberSequence: this.currentFilterContracts,
						purchaseOrder: this.currentPurchaseOrder,
						loadNumber: this.currentLoad,
						vessel: this.currentTransport,
						container: this.currentContainer,
						product: this.currentProduct,
						expectedEtbDate: this.etsFilterCache.value.id,
						realAtbDate: this.etaFilterCache.value.id,
						page: this.inLoadPage,
					});
					break;

				case 'gateInPage':
					this.getGateInCargo({
						vm: this,
						companyId: this.headerCompanyFilterCache.value.id,
						contractNumberSequence: this.currentFilterContracts,
						purchaseOrder: this.currentPurchaseOrder,
						loadNumber: this.currentLoad,
						vessel: this.currentTransport,
						container: this.currentContainer,
						product: this.currentProduct,
						expectedEtbDate: this.etsFilterCache.value.id,
						realAtbDate: this.etaFilterCache.value.id,
						page: this.gateInPage,
					});
					break;

				case 'shippedPage':
					this.getShippedCargo({
						vm: this,
						companyId: this.headerCompanyFilterCache.value.id,
						contractNumberSequence: this.currentFilterContracts,
						purchaseOrder: this.currentPurchaseOrder,
						loadNumber: this.currentLoad,
						vessel: this.currentTransport,
						container: this.currentContainer,
						product: this.currentProduct,
						expectedEtbDate: this.etsFilterCache.value.id,
						realAtbDate: this.etaFilterCache.value.id,
						page: this.shippedPage,
					});
					break;

				case 'deliveredPage':
					this.getDeliveredCargo({
						vm: this,
						companyId: this.headerCompanyFilterCache.value.id,
						contractNumberSequence: this.currentFilterContracts,
						purchaseOrder: this.currentPurchaseOrder,
						loadNumber: this.currentLoad,
						vessel: this.currentTransport,
						container: this.currentContainer,
						product: this.currentProduct,
						expectedEtbDate: this.etsFilterCache.value.id,
						realAtbDate: this.etaFilterCache.value.id,
						page: this.deliveredPage,
					});
					break;

				case 'gateOutPage':
					this.getGateOutCargo({
						vm: this,
						companyId: this.headerCompanyFilterCache.value.id,
						contractNumberSequence: this.currentFilterContracts,
						purchaseOrder: this.currentPurchaseOrder,
						loadNumber: this.currentLoad,
						vessel: this.currentTransport,
						container: this.currentContainer,
						product: this.currentProduct,
						expectedEtbDate: this.etsFilterCache.value,
						realAtbDate: this.etaFilterCache.value,
						page: this.gateOutPage,
					});
					break;

				default:
					break;
			}
		},
	},
	watch: {
		cargoInProductionPage(newValue) {
			this.productionPage = newValue + 1;
		},
		cargoInLoadingPage(newValue) {
			this.inLoadPage = newValue + 1;
		},
		cargoGateInPage(newValue) {
			this.gateInPage = newValue + 1;
		},
		cargoShippedPage(newValue) {
			this.shippedPage = newValue + 1;
		},
		cargoDeliveredPage(newValue) {
			this.deliveredPage = newValue + 1;
		},
		cargoGateOutPage(newValue) {
			this.gateOutPage = newValue + 1;
		},
		lastQueryString() {
			this.eraseState();
			this.resetAllPages();
			this.callAllTypesOfLoad();
		},
		headerCompanyFilterCache(value) {
			if (value.value !== null) {
				this.refreshFilters();
				this.$refs.filterManagement.clearCachedFilters();
				this.$refs.mapViewer.getMapStatus({
					vm: this,
					companyId: this.headerCompanyFilterCache.value.id,
					vehicleType: this.activeTab,
				});
				this.eraseState();
				this.resetAllPages();
				this.callAllTypesOfLoad();
			}
		},
		defaultSectionList(newValue) {
			if (newValue) {
				this.sectionList = newValue;
			}
		},
		activeTab() {
			this.callAllTypesOfLoad();
		},
	},
	computed: {
		...mapGetters('loadManagementFilters', [
			'contractsFilterCache',
			'poFilterCache',
			'loadFilterCache',
			'transportFilterCache',
			'containerFilterCache',
			'productFilterCache',
			'etsFilterCache',
			'etaFilterCache',
			'lastQueryString',
		]),
		...mapGetters('filters', ['companyFilter', 'headerCompanyFilterCache']),
		...mapGetters('loadManagement', [
			'cargoInProductionList',
			'cargoInProductionPage',
			'cargoInProductionMax',
			'isLoadingProduction',
			'cargoInLoadingList',
			'cargoInLoadingPage',
			'cargoInLoadingMax',
			'isLoadingInLoad',
			'cargoGateInList',
			'cargoGateInPage',
			'cargoGateInMax',
			'isLoadingGateIn',
			'cargoShippedList',
			'cargoShippedPage',
			'cargoShippedMax',
			'isLoadingShipped',
			'cargoDeliveredList',
			'cargoDeliveredPage',
			'cargoDeliveredMax',
			'isLoadingDelivered',
			'cargoGateOutList',
			'cargoGateOutPage',
			'cargoGateOutMax',
			'isLoadingGateOut',
		]),
		isWeb() {
			return this.windowWidth > 992;
		},
		shipColor() {
			if (this.activeTab !== 'ship') {
				return '#B47D4A';
			}
			return '#fff';
		},
		truckColor() {
			if (this.activeTab !== 'truck') {
				return '#B47D4A';
			}
			return '#fff';
		},
		dragOptions() {
			return {
				animation: 200,
				group: 'description',
				disabled: false,
				ghostClass: 'ghost',
			};
		},
		defaultSectionList() {
			if (this.activeTab === 'truck')
				return [
					{
						status: false,
						title: 'Produção',
						items: [
							{
								loadNumber: '576449',
								container: 'HLBU9112367',
								invoiceNumber: '1019964',
								vessel: 'HLBU9112367',
								origin: 'NAVEGANTES',
								gateInDate: '2023-07-31T03:00:00.000Z',
								gateOutDate: null,
								expectedEtbDate: '2023-08-07T03:00:00.000Z',
								realAtbDate: '2023-09-01T03:00:00.000Z',
								netWeight: 216.72,
							},
						],
						max: 50,
						page: 1,
						getSelector: 'productionPage',
					},
					{
						status: false,
						title: 'Carregamento',
						items: [
							{
								loadNumber: '1231234',
								container: 'HLBU9112367',
								invoiceNumber: '1019964',
								vessel: 'HLBU9112367',
								origin: 'NAVEGANTES',
								gateInDate: '2023-07-31T03:00:00.000Z',
								gateOutDate: null,
								expectedEtbDate: '2023-08-07T03:00:00.000Z',
								realAtbDate: '2023-09-01T03:00:00.000Z',
								netWeight: 27.09,
							},
						],
						max: 40,
						page: 2,
						getSelector: 'inLoadPage',
					},
					{
						status: true,
						title: 'Entrada no Portão de Origem',
						items: [
							{
								loadNumber: '123124',
								container: 'HLBU9112367',
								invoiceNumber: '1019964',
								vessel: 'HLBU9112367',
								origin: 'NAVEGANTES',
								gateInDate: '2023-07-31T03:00:00.000Z',
								gateOutDate: null,
								expectedEtbDate: '2023-08-07T03:00:00.000Z',
								realAtbDate: '2023-09-01T03:00:00.000Z',
								netWeight: 54.18,
							},
						],
						max: 30,
						page: 1,
						getSelector: 'gateInPage',
					},
					{
						status: false,
						title: 'Enviado',
						items: [
							{
								loadNumber: '576445',
								container: 'HLBU9112367',
								invoiceNumber: '1019964',
								vessel: 'HLBU9112367',
								origin: 'NAVEGANTES',
								gateInDate: '2023-07-31T03:00:00.000Z',
								gateOutDate: null,
								expectedEtbDate: '2023-08-07T03:00:00.000Z',
								realAtbDate: '2023-09-01T03:00:00.000Z',
								netWeight: 27.09,
							},
						],
						max: 20,
						page: 3,
						getSelector: 'shippedPage',
					},
					{
						status: true,
						title: 'Entregue no Ponto de Destino',
						items: [
							{
								loadNumber: '564465',
								container: 'HLBU9112367',
								invoiceNumber: '1019964',
								vessel: 'HLBU9112367',
								origin: 'NAVEGANTES',
								gateInDate: '2023-07-31T03:00:00.000Z',
								gateOutDate: null,
								expectedEtbDate: '2023-08-07T03:00:00.000Z',
								realAtbDate: '2023-09-01T03:00:00.000Z',
								netWeight: 27.09,
							},
						],
						max: 25,
						page: 2,
						getSelector: 'deliveredPage',
					},
					{
						status: false,
						title: 'Saída do Portão de Destino',
						items: [
							{
								loadNumber: '576442',
								container: 'HLBU9112367',
								invoiceNumber: '1019964',
								vessel: 'HLBU9112367',
								origin: 'NAVEGANTES',
								gateInDate: '2023-07-31T03:00:00.000Z',
								gateOutDate: null,
								expectedEtbDate: '2023-08-07T03:00:00.000Z',
								realAtbDate: '2023-09-01T03:00:00.000Z',
								netWeight: 27.09,
							},
						],
						max: 15,
						page: 1,
						getSelector: 'gateOutPage',
					},
				];

			return [
				{
					status: this.isLoadingProduction,
					title: this.$t('modules.load_management.sections.inProduction'),
					items: this.cargoInProductionList,
					max: this.cargoInProductionMax,
					page: this.productionPage,
					getSelector: 'productionPage',
				},
				{
					status: this.isLoadingInLoad,
					title: this.$t('modules.load_management.sections.loading'),
					items: this.cargoInLoadingList,
					max: this.cargoInLoadingMax,
					page: this.inLoadPage,
					getSelector: 'inLoadPage',
				},
				{
					status: this.isLoadingGateIn,
					title: this.$t('modules.load_management.sections.gateInOrigin'),
					items: this.cargoGateInList,
					max: this.cargoGateInMax,
					page: this.gateInPage,
					getSelector: 'gateInPage',
				},
				{
					status: this.isLoadingShipped,
					title: this.$t('modules.load_management.sections.shipped'),
					items: this.cargoShippedList,
					max: this.cargoShippedMax,
					page: this.shippedPage,
					getSelector: 'shippedPage',
				},
				{
					status: this.isLoadingDelivered,
					title: this.$t('modules.load_management.sections.deliveredToPOD'),
					items: this.cargoDeliveredList,
					max: this.cargoDeliveredMax,
					page: this.deliveredPage,
					getSelector: 'deliveredPage',
				},
				{
					status: this.isLoadingGateOut,
					title: this.$t('modules.load_management.sections.gateOutDestination'),
					items: this.cargoGateOutList,
					max: this.cargoGateOutMax,
					page: this.gateOutPage,
					getSelector: 'gateOutPage',
				},
			];
		},
	},
};
</script>

<style lang="scss">
@import 'LoadManagement.scss';
</style>
