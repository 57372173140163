import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store/store';
import VueI18n from 'vue-i18n';
import messages from '@/lang/messages';
import VueToastr from 'vue-toastr';
import Multiselect from 'vue-multiselect';
import VueObserveVisibility from 'vue-observe-visibility';
import VTooltip from 'v-tooltip';
import zoomPlugin from 'chartjs-plugin-zoom';
import VComp from '@vue/composition-api';
import { Chart, registerables } from 'chart.js';
import { Settings } from 'luxon';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { initializeValidatorLib } from './utils/validators';

Settings.defaultLocale = 'en-US';
Chart.register(...registerables, zoomPlugin);
Vue.use(VComp);
Vue.use(VueToastr);
Vue.use(VTooltip);
Vue.use(VueI18n);
Vue.use(VueObserveVisibility);
Vue.use(VueSweetalert2);
Vue.component('multiselect', Multiselect);

const currentLocale = localStorage.getItem('systemLanguage') || 'en-US';

initializeValidatorLib(currentLocale);

export const i18n = new VueI18n({
	locale: currentLocale,
	fallbackLocale: 'en-US',
	globalInjection: true,
	messages,
});

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
