import api from '@/utils/api';
import {
	CLEAR_CACHED_FILTERS,
	CLEAR_COMPANY_FILTER,
	CLEAR_CONTRACTS_FILTER,
	CLEAR_HEADER_COMPANY_FILTER,
	CLEAR_MARKET_FILTER,
	CLEAR_PO_FILTER,
	CLEAR_USER_EMAIL_FILTER,
	CLEAR_USER_NAME_FILTER,
	//company
	COMPANY_FILTER_REQUEST,
	COMPANY_FILTER_REQUEST_ALL,
	COMPANY_FILTER_REQUEST_ERROR,
	COMPANY_FILTER_REQUEST_SUCCESS,
	//contracts
	CONTRACTS_FILTER_REQUEST,
	CONTRACTS_FILTER_REQUEST_ERROR,
	CONTRACTS_FILTER_REQUEST_SUCCESS,
	//DEFAULT COMPANY
	DEFINE_DEFAULT_HEADER_COMPANY,
	//header company
	HEADER_COMPANY_FILTER_REQUEST,
	HEADER_COMPANY_FILTER_REQUEST_ERROR,
	HEADER_COMPANY_FILTER_REQUEST_SUCCESS,
	MARKET_FILTER_REQUEST,
	MARKET_FILTER_REQUEST_ERROR,
	MARKET_FILTER_REQUEST_SUCCESS,
	PO_FILTER_REQUEST,
	PO_FILTER_REQUEST_ERROR,
	PO_FILTER_REQUEST_SUCCESS,
	SAVE_COMPANY_FILTER,
	SAVE_CONTRACT_END,
	SAVE_CONTRACT_FILTER,
	SAVE_CONTRACT_INITIAL,
	SAVE_CONTRACT_STATUS,
	SAVE_HEADER_COMPANY_FILTER,
	SAVE_MANAGEMENT_COMPANY_FILTER,
	SAVE_MANAGEMENT_CONTRACT_FILTER,
	SAVE_MANAGEMENT_MARKET_FILTER,
	SAVE_MANAGEMENT_PO_FILTER,
	SAVE_MARKET_FILTER,
	SAVE_PO_FILTER,
	SAVE_RELEASE_END,
	//contracts dates
	SAVE_RELEASE_INITIAL,
	//email
	USER_EMAIL_FILTER_REQUEST,
	USER_EMAIL_FILTER_REQUEST_ERROR,
	USER_EMAIL_FILTER_REQUEST_SUCCESS,
	//user name
	USER_NAME_FILTER_REQUEST,
	USER_NAME_FILTER_REQUEST_ERROR,
	USER_NAME_FILTER_REQUEST_SUCCESS,
} from './actions';

const { http } = api.getInstance();

const defaultState = () => ({
	//user name
	userNameFilter: [],
	userNameFilterMeta: [],
	isLoadingUsersFilter: '',

	//email
	userEmailFilter: [],
	userEmailFilterMeta: [],
	isLoadingUsersEmailFilter: '',

	//market
	marketFilter: [],
	marketFilterMeta: [],
	marketFilterCache: {
		value: [],
	},
	marketManagementFilterCache: {
		value: {},
	},
	isLoadingMarketFilter: '',

	//purchaseOrder (PO)
	poFilter: [],
	poFilterMeta: [],
	poFilterCache: {
		value: [],
	},
	poManagementFilterCache: {
		value: {},
	},
	isLoadingPoFilter: '',

	//company
	companyFilter: [],
	companyFilterMeta: [],
	companyFilterCache: {
		value: [],
	},
	companyManagementFilterCache: {
		value: {},
	},
	isLoadingCompanyFilter: '',

	//header company
	headerCompanyFilter: [],
	headerCompanyFilterMeta: [],
	headerCompanyFilterCache: {
		value: [],
	},
	isLoadingHeaderCompanyFilter: '',

	//contracts
	contractsFilter: [],
	contractsFilterMeta: [],
	contractsFilterCache: {
		value: [],
	},
	contractsManagementFilterCache: {
		value: {},
	},
	isLoadingContractsFilter: '',

	//contracts dates
	initialReleaseDateCache: {
		value: {},
	},
	endReleaseDateCache: {
		value: {},
	},
	contractInitialDateCache: {
		value: {},
	},
	contractEndDateCache: {
		value: {},
	},
	contractStatusCache: {
		value: {},
	},
});

const state = defaultState();

const getters = {
	//user name
	userNameFilter: (state) => state.userNameFilter,
	userNameFilterMeta: (state) => state.userNameFilterMeta,
	isLoadingUsersFilter: (state) => state.isLoadingUsersFilter === 'loading',
	//email
	userEmailFilter: (state) => state.userEmailFilter,
	userEmailFilterMeta: (state) => state.userEmailFilterMeta,
	isLoadingUsersEmailFilter: (state) => state.isLoadingUsersEmailFilter === 'loading',
	//market
	marketFilter: (state) => state.marketFilter,
	marketFilterMeta: (state) => state.marketFilterMeta,
	marketFilterCache: (state) => state.marketFilterCache,
	marketManagementFilterCache: (state) => state.marketManagementFilterCache,
	isLoadingMarketFilter: (state) => state.isLoadingMarketFilter === 'loading',
	//purchaseOrder (PO)
	poFilter: (state) => state.poFilter,
	poFilterMeta: (state) => state.poFilterMeta,
	poFilterCache: (state) => state.poFilterCache,
	poManagementFilterCache: (state) => state.poManagementFilterCache,
	isLoadingPoFilter: (state) => state.isLoadingPoFilter === 'loading',
	//company
	companyFilter: (state) => state.companyFilter,
	companyFilterMeta: (state) => state.companyFilterMeta,
	companyFilterCache: (state) => state.companyFilterCache,
	companyManagementFilterCache: (state) => state.companyManagementFilterCache,
	isLoadingCompanyFilter: (state) => state.isLoadingCompanyFilter === 'loading',
	//header company
	headerCompanyFilter: (state) => state.headerCompanyFilter,
	headerCompanyFilterMeta: (state) => state.headerCompanyFilterMeta,
	headerCompanyFilterCache: (state) => state.headerCompanyFilterCache,
	isLoadingHeaderCompanyFilter: (state) => state.isLoadingHeaderCompanyFilter === 'loading',
	//contracts
	contractsFilter: (state) => state.contractsFilter,
	contractsFilterMeta: (state) => state.contractsFilterMeta,
	contractsFilterCache: (state) => state.contractsFilterCache,
	contractsManagementFilterCache: (state) => state.contractsManagementFilterCache,
	isLoadingContractsFilter: (state) => state.isLoadingContractsFilter === 'loading',
	//contracts dates
	initialReleaseDateCache: (state) => state.initialReleaseDateCache,
	endReleaseDateCache: (state) => state.endReleaseDateCache,
	contractInitialDateCache: (state) => state.contractInitialDateCache,
	contractEndDateCache: (state) => state.contractEndDateCache,
	contractStatusCache: (state) => state.contractStatusCache,
};

const mutations = {
	//USER NAME FILTER
	[USER_NAME_FILTER_REQUEST]: (state) => {
		state.userNameFilterMeta = [];
		state.isLoadingUsersFilter = 'loading';
	},
	[USER_NAME_FILTER_REQUEST_SUCCESS]: (state, data) => {
		if (data.items && data.items.length) {
			data.items.forEach((item) => {
				if (!state.userNameFilter.some((e) => e.name == item.name)) {
					state.userNameFilter.push({ id: item.name, name: item.name });
				}
			});
		}
		state.userNameFilterMeta = data.meta;
		state.isLoadingUsersFilter = 'success';
	},
	[USER_NAME_FILTER_REQUEST_ERROR]: (state) => {
		state.userNameFilter = [];
		state.userNameFilterMeta = [];
		state.isLoadingUsersFilter = 'error';
	},
	[CLEAR_USER_NAME_FILTER]: (state) => {
		state.userNameFilter = [];
	},
	//MARKET FILTER
	[MARKET_FILTER_REQUEST]: (state) => {
		state.marketFilterMeta = [];
		state.isLoadingMarketFilter = 'loading';
	},
	[MARKET_FILTER_REQUEST_SUCCESS]: (state, data) => {
		if (data.items && data.items.length) {
			data.items.forEach((item) => {
				if (!state.marketFilter.some((e) => e.market == item.market)) {
					state.marketFilter.push({ id: item.market, name: item.market });
				}
			});
		}
		state.marketFilterMeta = data.meta;
		state.isLoadingMarketFilter = 'success';
	},
	[MARKET_FILTER_REQUEST_ERROR]: (state) => {
		state.marketFilter = [];
		state.marketFilterMeta = [];
		state.isLoadingMarketFilter = 'error';
	},
	[CLEAR_MARKET_FILTER]: (state) => {
		state.marketFilter = [];
	},
	[SAVE_MARKET_FILTER]: (state, data) => {
		state.marketFilterCache = data;
	},
	[SAVE_MANAGEMENT_MARKET_FILTER]: (state, data) => {
		state.marketManagementFilterCache = data;
	},
	//PURCHASE ORDER (PO)
	[PO_FILTER_REQUEST]: (state) => {
		state.poFilterMeta = [];
		state.isLoadingPoFilter = 'loading';
	},
	[PO_FILTER_REQUEST_SUCCESS]: (state, data) => {
		if (data.items && data.items.length) {
			data.items.forEach((item) => {
				if (
					item.purchaseOrder != null &&
					!state.poFilter.some((e) => e.purchaseOrder == item.purchaseOrder)
				) {
					state.poFilter.push({ id: item.purchaseOrder, name: item.purchaseOrder });
				}
			});
		}
		state.poFilterMeta = data.meta;
		state.isLoadingPoFilter = 'success';
	},
	[PO_FILTER_REQUEST_ERROR]: (state) => {
		state.poFilter = [];
		state.poFilterMeta = [];
		state.isLoadingPoFilter = 'error';
	},
	[CLEAR_PO_FILTER]: (state) => {
		state.poFilter = [];
	},
	[SAVE_PO_FILTER]: (state, data) => {
		state.poFilterCache = data;
	},
	[SAVE_MANAGEMENT_PO_FILTER]: (state, data) => {
		state.poManagementFilterCache = data;
	},
	//EMAIL FILTER
	[USER_EMAIL_FILTER_REQUEST]: (state) => {
		state.userEmailFilterMeta = [];
		state.isLoadingUsersEmailFilter = 'loading';
	},
	[USER_EMAIL_FILTER_REQUEST_SUCCESS]: (state, data) => {
		if (data.items && data.items.length) {
			data.items.forEach((item) => {
				if (!state.userEmailFilter.some((e) => e.name == item.email)) {
					state.userEmailFilter.push({ id: item.email, name: item.email });
				}
			});
		}
		state.userEmailFilterMeta = data.meta;
		state.isLoadingUsersEmailFilter = 'success';
	},
	[USER_EMAIL_FILTER_REQUEST_ERROR]: (state) => {
		state.userEmailFilter = [];
		state.userEmailFilterMeta = [];
		state.isLoadingUsersEmailFilter = 'error';
	},
	[CLEAR_USER_EMAIL_FILTER]: (state) => {
		state.userEmailFilter = [];
	},

	//COMPANY FILTER
	[COMPANY_FILTER_REQUEST]: (state) => {
		state.companyFilterMeta = [];
		state.isLoadingCompanyFilter = 'loading';
	},
	[COMPANY_FILTER_REQUEST_ALL]: (state) => {
		state.companyFilterMeta = [];
		state.isLoadingCompanyFilter = 'loading';
	},
	[COMPANY_FILTER_REQUEST_SUCCESS]: (state, data) => {
		if (data.items?.length > 0) {
			data.items.forEach((item) => {
				if (!state.companyFilter.some((e) => e.name == item.name)) {
					state.companyFilter.push({ id: item.id, name: item.name });
				}
			});
		}

		state.isLoadingCompanyFilter = 'success';
	},
	[COMPANY_FILTER_REQUEST_ERROR]: (state) => {
		state.companyFilter = [];
		state.companyFilterMeta = [];
		state.isLoadingCompanyFilter = 'error';
	},
	[SAVE_COMPANY_FILTER]: (state, data) => {
		state.companyFilterCache = data;
	},
	[SAVE_MANAGEMENT_COMPANY_FILTER]: (state, data) => {
		state.companyManagementFilterCache = data;
	},
	[CLEAR_COMPANY_FILTER]: (state) => {
		state.companyFilter = [];
	},
	//HEADER COMPANY FILTER
	[HEADER_COMPANY_FILTER_REQUEST]: (state) => {
		state.headerCompanyFilterMeta = [];
		state.isLoadingHeaderCompanyFilter = 'loading';
	},
	[HEADER_COMPANY_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.headerCompanyFilter = [];
		if (data.items && data.items.length) {
			data.items.forEach((item) => {
				if (!state.headerCompanyFilter.some((e) => e.name == item.name)) {
					state.headerCompanyFilter.push({ id: item.id, name: item.name });
				}
			});
		}
		state.headerCompanyFilterMeta = data.meta;
		state.isLoadingHeaderCompanyFilter = 'success';
	},
	[HEADER_COMPANY_FILTER_REQUEST_ERROR]: (state) => {
		state.headerCompanyFilter = [];
		state.headerCompanyFilterMeta = [];
		state.isLoadingHeaderCompanyFilter = 'error';
	},
	[DEFINE_DEFAULT_HEADER_COMPANY]: (state) => {
		if (state.headerCompanyFilter.length > 0) {
			let cachedFilter = {
				id: state.headerCompanyFilter[0].id,
				name: state.headerCompanyFilter[0].name,
			};
			localStorage.setItem('headerCompany', JSON.stringify(cachedFilter));
			state.headerCompanyFilterCache = {
				value: { id: cachedFilter.id, name: cachedFilter.name },
			};
		}
	},
	[SAVE_HEADER_COMPANY_FILTER]: (state, data) => {
		state.headerCompanyFilterCache = data;
	},
	[CLEAR_HEADER_COMPANY_FILTER]: (state) => {
		state.headerCompanyFilter = [];
	},
	//CONTRACTS FILTER
	[CONTRACTS_FILTER_REQUEST]: (state) => {
		state.contractsFilterMeta = [];
		state.isLoadingContractsFilter = 'loading';
	},
	[CONTRACTS_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.contractsFilter = [];
		if (data.items && data.items.length) {
			data.items.forEach((item) => {
				if (!state.contractsFilter.some((e) => e.name == item.contractNumber)) {
					state.contractsFilter.push({ id: item.contractNumber, name: item.contractNumber });
				}
			});
		}
		state.contractsFilterMeta = data.meta;
		state.isLoadingContractsFilter = 'success';
	},
	[CONTRACTS_FILTER_REQUEST_ERROR]: (state) => {
		state.contractsFilter = [];
		state.contractsFilterMeta = [];
		state.isLoadingContractsFilter = 'error';
	},
	[SAVE_CONTRACT_FILTER]: (state, data) => {
		state.contractsFilterCache = data;
	},
	[SAVE_MANAGEMENT_CONTRACT_FILTER]: (state, data) => {
		state.contractsManagementFilterCache = data;
	},
	[CLEAR_CONTRACTS_FILTER]: (state) => {
		state.contractsFilter = [];
	},
	//contract dates
	[SAVE_RELEASE_INITIAL]: (state, data) => {
		state.initialReleaseDateCache = data;
	},
	[SAVE_RELEASE_END]: (state, data) => {
		state.endReleaseDateCache = data;
	},
	[SAVE_CONTRACT_INITIAL]: (state, data) => {
		state.contractInitialDateCache = data;
	},
	[SAVE_CONTRACT_END]: (state, data) => {
		state.contractEndDateCache = data;
	},
	[SAVE_CONTRACT_STATUS]: (state, data) => {
		state.contractStatusCache = data;
	},
	[SAVE_PO_FILTER]: (state, data) => {
		state.poFilterCache = data;
	},
	[SAVE_MARKET_FILTER]: (state, data) => {
		state.marketFilterCache = data;
	},
	[SAVE_PO_FILTER]: (state, data) => {
		state.poFilterCache = data;
	},
	[SAVE_MARKET_FILTER]: (state, data) => {
		state.marketFilterCache = data;
	},
	[CLEAR_CACHED_FILTERS]: (state) => {
		state.companyFilterCache = {
			value: [],
		};
		state.companyManagementFilterCache = {
			value: {},
		};
		state.marketFilterCache = {
			value: [],
		};
		state.poFilterCache = {
			value: [],
		};
		state.contractsFilterCache = {
			value: [],
		};
		state.contractsManagementFilterCache = {
			value: {},
		};
		state.initialReleaseDateCache = {
			value: {},
		};
		state.endReleaseDateCache = {
			value: {},
		};
		state.contractInitialDateCache = {
			value: {},
		};
		state.contractEndDateCache = {
			value: {},
		};
		state.contractStatusCache = {
			value: {},
		};
		state.marketManagementFilterCache = {
			value: {},
		};
		state.poManagementFilterCache = {
			value: {},
		};
	},
};

const actions = {
	//MARKET FILTER
	[MARKET_FILTER_REQUEST]: ({ commit }, { vm, params }) => {
		return new Promise((resolve) => {
			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;
			if (!headerCompanyIdStored) {
				const localStorageCache = localStorage.getItem('headerCompany');
				headerCompanyIdStored = JSON.parse(localStorageCache).id;
			}

			commit(CLEAR_MARKET_FILTER);
			commit(MARKET_FILTER_REQUEST);

			let url = `/finances/marketsByFilters?companyId=${headerCompanyIdStored}&page=1&limit=20`;
			if (!params) commit(CLEAR_MARKET_FILTER);

			if (params.has('companyId')) {
				url = `/finances/marketsByFilters?${params}`;
			} else {
				url = `/finances/marketsByFilters?companyId=${headerCompanyIdStored}&${params}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(MARKET_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(MARKET_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_MANAGEMENT_MARKET_FILTER]: ({ commit }, data) => {
		commit(SAVE_MANAGEMENT_MARKET_FILTER, data);
	},
	//PO FILTER
	[PO_FILTER_REQUEST]: ({ commit }, { vm, params }) => {
		return new Promise((resolve) => {
			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;
			if (!headerCompanyIdStored) {
				const localStorageCache = localStorage.getItem('headerCompany');
				headerCompanyIdStored = JSON.parse(localStorageCache).id;
			}
			commit(PO_FILTER_REQUEST);
			let url = `/loads/purchaseOrdersByFilters?companyId=${headerCompanyIdStored}&page=1&limit=20`;
			if (params) {
				url = `/loads/purchaseOrdersByFilters?companyId=${headerCompanyIdStored}&${params}`;
			} else {
				commit(CLEAR_PO_FILTER);
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(PO_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(PO_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_MANAGEMENT_PO_FILTER]: ({ commit }, data) => {
		commit(SAVE_MANAGEMENT_PO_FILTER, data);
	},
	//USER NAME FILTER
	[USER_NAME_FILTER_REQUEST]: ({ commit }, { vm, params }) => {
		return new Promise((resolve) => {
			commit(USER_NAME_FILTER_REQUEST);

			let url = `/users?page=1&limit=20`;

			if (params) {
				url = `/users?${params}`;
			} else {
				commit(CLEAR_USER_NAME_FILTER);
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(USER_NAME_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(USER_NAME_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	//EMAIL FILTER
	[USER_EMAIL_FILTER_REQUEST]: ({ commit }, { vm, params }) => {
		return new Promise((resolve) => {
			commit(USER_EMAIL_FILTER_REQUEST);

			let url = `/users?page=1&limit=20`;

			if (params) {
				url = `/users?${params}`;
			} else {
				commit(CLEAR_USER_EMAIL_FILTER);
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(USER_EMAIL_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(USER_EMAIL_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	//COMPANY FILTER
	[COMPANY_FILTER_REQUEST]: ({ commit }, { vm, params }) => {
		return new Promise((resolve) => {
			commit(COMPANY_FILTER_REQUEST);

			let url = `/companies?page=1&limit=20`;

			if (params) {
				url = `/companies?${params}`;
			} else {
				commit(CLEAR_COMPANY_FILTER);
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(COMPANY_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(COMPANY_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_COMPANY_FILTER]: ({ commit }, data) => {
		commit(SAVE_COMPANY_FILTER, data);
	},
	[SAVE_MANAGEMENT_COMPANY_FILTER]: ({ commit }, data) => {
		commit(SAVE_MANAGEMENT_COMPANY_FILTER, data);
	},
	//HEADER COMPANY FILTER
	[HEADER_COMPANY_FILTER_REQUEST]: ({ commit }, { vm, params }) => {
		return new Promise((resolve) => {
			commit(HEADER_COMPANY_FILTER_REQUEST);

			let url = `/companies?page=1&limit=20`;

			if (params) {
				url = `/companies?${params}`;
			} else {
				commit(CLEAR_HEADER_COMPANY_FILTER);
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(HEADER_COMPANY_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(HEADER_COMPANY_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[DEFINE_DEFAULT_HEADER_COMPANY]: ({ commit }) => {
		commit(DEFINE_DEFAULT_HEADER_COMPANY);
	},
	[SAVE_HEADER_COMPANY_FILTER]: ({ commit }, data) => {
		commit(SAVE_HEADER_COMPANY_FILTER, data);
	},
	//COMPANY ALL FILTER
	[COMPANY_FILTER_REQUEST_ALL]: ({ commit }, { vm, params }) => {
		return new Promise((resolve) => {
			commit(COMPANY_FILTER_REQUEST_ALL);

			let url = `/companies/all?page=1&limit=10`;

			if (params) {
				url = `/companies/all?${params}`;
			} else {
				commit(CLEAR_COMPANY_FILTER);
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(COMPANY_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(COMPANY_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	//CONTRACTS FILTER
	[CONTRACTS_FILTER_REQUEST]: ({ commit }, { vm, params }) => {
		return new Promise((resolve) => {
			commit(CONTRACTS_FILTER_REQUEST);
			let url = `/contracts?page=1&limit=20`;

			if (params) {
				url = `/contracts?${params}`;
			} else {
				commit(CLEAR_CONTRACTS_FILTER);
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CONTRACTS_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CONTRACTS_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_CONTRACT_FILTER]: ({ commit }, data) => {
		commit(SAVE_CONTRACT_FILTER, data);
	},
	[SAVE_MANAGEMENT_CONTRACT_FILTER]: ({ commit }, data) => {
		commit(SAVE_MANAGEMENT_CONTRACT_FILTER, data);
	},
	[SAVE_PO_FILTER]: ({ commit }, data) => {
		commit(SAVE_PO_FILTER, data);
	},
	[SAVE_MARKET_FILTER]: ({ commit }, data) => {
		commit(SAVE_MARKET_FILTER, data);
	},
	[CLEAR_MARKET_FILTER]: ({ commit }) => {
		commit(CLEAR_MARKET_FILTER);
	},
	[CLEAR_PO_FILTER]: ({ commit }) => {
		commit(CLEAR_PO_FILTER);
	},
	//contract dates
	[SAVE_RELEASE_INITIAL]: ({ commit }, data) => {
		commit(SAVE_RELEASE_INITIAL, data);
	},
	[SAVE_RELEASE_END]: ({ commit }, data) => {
		commit(SAVE_RELEASE_END, data);
	},
	[SAVE_CONTRACT_INITIAL]: ({ commit }, data) => {
		commit(SAVE_CONTRACT_INITIAL, data);
	},
	[SAVE_CONTRACT_END]: ({ commit }, data) => {
		commit(SAVE_CONTRACT_END, data);
	},
	[SAVE_CONTRACT_STATUS]: ({ commit }, data) => {
		commit(SAVE_CONTRACT_STATUS, data);
	},
	[CLEAR_CACHED_FILTERS]: ({ commit }, data) => {
		commit(CLEAR_CACHED_FILTERS, data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
