export default {
	placeholder: {
		search: 'بحث',
	},
	header: {
		contractNumber: 'العقد',
		contractDate: 'تاريخ العقد',
		competenceMonth: 'شهر الكفاءة',
		releaseDate: 'تاريخ الإصدار',
		company: 'الشركة',
		status: 'المرحلة',
		totalVolume: 'الحجم الإجمالي',
	},
	detail: {
		actualDateOfBoarding: 'التاريخ الفعلي للتحميل',
		awbCourier: 'شركة بوليصة الشحن الجوي',
		balance: 'الرصيد',
		billLanding: 'بوليصة الشحن',
		billLandingDate: 'تاريخ بوليصة الشحن',
		blDate: 'تاريخ BL',
		blDateId: 'معرف تاريخ BL',
		bookingNumber: 'رقم الحجز',
		boardingType: 'نوع الصعود',
		borderCrossingDate: 'تاريخ عبور الحدود',
		borderPost: 'نقطة الحدود',
		borderPostsCode: 'رمز نقطة الحدود',
		branch: 'الفرع',
		brand: 'العلامة التجارية',
		brandCode: 'رمز العلامة التجارية',
		business: 'الأعمال',
		closedContractSequence: 'تسلسل العقد المغلق',
		cneCode: 'رمز CNE',
		container: 'الحاوية',
		contractSequence: 'تسلسل العقد',
		country: 'الدولة',
		courierDate: 'تاريخ البريد السريع',
		courierCompany: 'شركة البريد السريع',
		csiIssuanceDate: 'تاريخ إصدار CSI',
		destination: 'الوجهة',
		destinationPort: 'ميناء الوجهة',
		destinationPort4: 'ميناء الوجهة 4',
		dsInternationalOfc: 'مكتب دي إس إنترناشيونال',
		dueSituationCode: 'رمز الحالة المستحقة',
		endShipDate: 'تاريخ انتهاء الشحن',
		etaPodDate: 'تاريخ الوصول المتوقع إلى ميناء التفريغ',
		etdPolDate: 'تاريخ المغادرة المتوقع من ميناء التحميل',
		etdRealDate: 'تاريخ المغادرة الفعلي',
		etsRfsDate: 'تاريخ الإبحار المتوقع حسب وثيقة الطلب',
		etsRfsId: 'معرف ETS RFS',
		expectedEtdDate: 'تاريخ ETS POL المتوقع',
		expectedEtbDate: 'تاريخ ATS POL المتوقع',
		expectedEtsDate: 'تاريخ ETS المتوقع',
		gateInDate: 'تاريخ الدخول من البوابة',
		idBlock: 'معرف الحظر',
		idStatus: 'معرف الحالة',
		incoterm: 'شروط التجارة الدولية (Incoterm)',
		initials: 'الأحرف الأولى',
		invoiceNumber: 'رقم الفاتورة',
		invoicedDate: 'تاريخ الفوترة',
		invoiceIssuanceDate: 'تاريخ إصدار الفاتورة',
		loadNumber: 'رقم التحميل',
		loadPlace: 'مكان التحميل',
		loadCode: 'رمز التحميل',
		loadingDate: 'تاريخ التحميل',
		localization: 'الموقع',
		market: 'السوق',
		groupingDescription: 'وصف التجميع',
		maxGroupingQuantity: 'أقصى كمية للتجميع',
		minGroupingQuantity: 'أقل كمية للتجميع',
		netWeight: 'الوزن الصافي',
		nfiIssuanceDate: 'تاريخ إصدار NFI',
		origin: 'الأصل',
		paymentDeadline: 'الموعد النهائي للدفع',
		paymentDeadlineCode: 'رمز الموعد النهائي للدفع',
		paymentMethod: 'طريقة الدفع',
		paymentMode: 'وضع الدفع',
		permissionImt: 'إذن IMT',
		permissionImtDate: 'تاريخ إذن IMT',
		planningCode: 'رمز التخطيط',
		plannerLoadingDate: 'تاريخ تحميل المخطط',
		pod: 'ميناء التفريغ (POD)',
		pol: 'ميناء التحميل (POL)',
		priceType: 'نوع السعر',
		productionMonth: 'شهر الإنتاج',
		productionDate: 'تاريخ الإنتاج',
		productName: 'اسم المنتج',
		provider: 'المورد',
		purchaseOrder: 'أمر الشراء',
		purchaseOrderClient: 'العميل حسب أمر الشراء',
		realAtdDate: 'تاريخ ATD الفعلي',
		realAtbDate: 'تاريخ الوصول الفعلي إلى POD',
		realArrivalDate: 'تاريخ الوصول الفعلي',
		regional: 'إقليمي',
		releaseSequenceDate: 'تاريخ تسلسل الإصدار',
		restritionCode: 'رمز التقييد',
		remarks: 'ملاحظات',
		sentDraftDate: 'تاريخ إرسال المسودة',
		scheduledDate: 'التاريخ المجدول',
		shippingCompany: 'شركة الشحن',
		shippingLine: 'خط الشحن',
		sif: 'ملف SIF',
		sku: 'رمز SKU',
		startShipDate: 'تاريخ بدء الشحن',
		statusClosure: 'حالة الإغلاق',
		statusPrePaid: 'الحالة: مدفوع مسبقاً',
		status: 'تسلسل الحالة',
		stockLocation: 'موقع المخزون',
		totalGrossWeight: 'إجمالي الوزن الإجمالي',
		totalKg: 'إجمالي الكيلوجرام',
		totalVolume: 'الحجم الإجمالي',
		traderName: 'اسم التاجر',
		unitPrice: 'سعر الوحدة',
		vehiclePlate: 'لوحة المركبة',
		vessel: 'السفينة',
		weeksToShip: 'عدد الأسابيع للشحن',
		stage: 'الحالة',
	},
};
