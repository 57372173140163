<template>
	<div class="chart-container holder">
		<template v-for="status in data">
			<div
				v-if="status.amount != 0"
				class="chart-container"
				:key="status.sequence"
				:class="getClassForStatus(status.sequence)"
				:style="{
					width: `${status.percentage}%`,
					backgroundColor: getColor(status.sequence),
				}"
				v-tooltip.top-center="{
					content: getTooltipContent(status.sequence, status.amount),
				}"
			/>
		</template>
	</div>
</template>

<script>
import getStatusTranslation from '@/utils/getStatusTranslation';

export default {
	name: 'StatusChart',
	components: {},
	props: {
		isWeb: {
			required: true,
		},
		data: {
			required: true,
		},
	},
	data: () => ({}),
	methods: {
		getClassForStatus(sequence) {
			const statusClassMap = {
				'Em processamento': 'chart-container__in-processing',
				'Restrições cliente': 'chart-container__client-restrictions',
				'Em produção': 'chart-container__in-production',
				'Em carregamento': 'chart-container__loading',
				'Gate in origem': 'chart-container__gate-in-origin',
				'Aguarda pré pgto': 'chart-container__awaiting-pre-payment',
				Embarcado: 'chart-container__shipped',
				'Documentação enviada': 'chart-container__documentation-sent',
				'Documentação entregue': 'chart-container__documentation-delivered',
				'Entregue no POD': 'chart-container__delivered-at-pod',
				'Gate out destino': 'chart-container__gate-out-destination',
				'Vazio devolvido': 'chart-container__empty-returned',
				Aberta: 'chart-container__open',
				'Aberta em atraso': 'chart-container__open-late',
				'Pago parcial': 'chart-container__partially-paid',
				'Pago total': 'chart-container__fully-paid',
				Finalizado: 'chart-container__finished',
			};
			return statusClassMap[sequence] || 'chart-container__unknown';
		},
		getTooltipContent(sequence, amount) {
			return `
			<div class="custom-tooltip">
				<span class="custom-tooltip__text" style="color: ${this.getColor(sequence)};">
					${getStatusTranslation(sequence.toUpperCase())} (${amount})
				</span>
			</div>`;
		},
		getColor(param) {
			return (
				{
					'Em processamento': '#808080',
					'Restrições cliente': '#FF4500',
					'Em produção': '#1E90FF',
					'Em carregamento': '#FFD700',
					'Gate in origem': '#8A2BE2',
					'Aguarda pré pgto': '#FF6347',
					Embarcado: '#4682B4',
					'Documentação enviada': '#00CED1',
					'Documentação entregue': '#32CD32',
					'Entregue no POD': '#228B22',
					'Gate out destino': '#6B8E23',
					'Vazio devolvido': '#2E8B57',
					Aberta: '#D2691E',
					'Aberta em atraso': '#B22222',
					'Pago parcial': '#FF8C00',
					'Pago total': '#00FF7F',
					Finalizado: '#008000',
				}[param] || '#CCCCCC'
			);
		},
	},
};
</script>

<style scoped lang="scss">
@import 'StatusChart.scss';
</style>
