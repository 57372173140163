<template>
	<div id="doc-management" class="doc-management">
		<Content>
			<template v-slot:header>
				<MobileFilter v-if="!isWeb" :fullWidth="true">
					<template v-slot:inputs-list>
						<FilterList ref="filterDocManagement" :isWeb="isWeb" @refreshFilters="refreshFilters" />
					</template>
				</MobileFilter>
				<WebFilter v-else>
					<template v-slot:inputs-list>
						<FilterList ref="filterDocManagement" :isWeb="isWeb" @refreshFilters="refreshFilters" />
					</template>
				</WebFilter>
			</template>
			<template v-slot:body>
				<div class="doc-management__body">
					<DefaultLoader :dataStatus="isLoadingDocsList" />
					<DocManagementRow
						:ref="`document-row-${docs.invoiceNumber}`"
						v-for="docs in docsList"
						:status="loadIsDone"
						:key="docs.id"
						:document="docs"
						:isWeb="isWeb"
						@closeAllTables="closeAllTables"
					>
						<DocManagementDetail
							:invoiceNumber="docs.invoiceNumber"
							slot="body"
							:isWeb="isWeb"
							@documentToReprove="documentToReprove"
						/>
						<div class="row" style="display: flex; width: 100%; gap: 10px" slot="footer">
							<ContainerTable :invoiceNumber="docs.invoiceNumber" :isWeb="isWeb" />
							<CourrierTable :invoiceNumber="docs.invoiceNumber" :isWeb="isWeb" />
						</div>
					</DocManagementRow>
					<Pagination
						ref="pagination"
						v-if="isLoadingDocsList == 'success'"
						:totalPages="pagination.totalPages"
						:currentFromReq="pagination.currentPage"
						@tableRequest="changePage"
					/>
				</div>
			</template>
		</Content>

		<ReprovalModal
			:isWeb="isWeb"
			:reproveDocument="reproveDocument"
			v-show="showRepprovalModal"
			@handleModal="handleModal"
			@confirmReproval="handleConfirmReproval"
		/>
	</div>
</template>

<script>
import Content from '@/components/shared/Content/Content.vue';
import MobileFilter from '@/components/shared/MobileFilter/MobileFilter.vue';
import WebFilter from '@/components/shared/WebFilter/WebFilter.vue';
import FilterList from '@/components/modules/DocManagement/Filter/FilterList.vue';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';
import DocManagementRow from '@/components/modules/DocManagement/DocManagementRow/DocManagementRow.vue';
import ContainerTable from '@/components/modules/DocManagement/ContainerTable/ContainerTable.vue';
import CourrierTable from '@/components/modules/DocManagement/CourrierTable/CourrierTable.vue';
import DocManagementDetail from '@/components/modules/DocManagement/DocManagementDetail/DocManagementDetail.vue';
import DefaultLoader from '@/components/shared/DefaultLoader/DefaultLoader.vue';
import Pagination from '@/components/shared/Pagination/Pagination';

import ReprovalModal from '@/components/modules/DocManagement/ReprovalModal/ReprovalModal.vue';

import { mapGetters, mapActions } from 'vuex';
import {
	DOC_INVOICE_FILTER_REQUEST,
	DOC_CONTRACT_FILTER_REQUEST,
	DOC_SEQUENCE_FILTER_REQUEST,
	DOC_PO_FILTER_REQUEST,
	DOC_CONTAINER_FILTER_REQUEST,
	DOC_VESSEL_FILTER_REQUEST,
	DOC_POD_FILTER_REQUEST,
	DOC_CARGO_FILTER_REQUEST,
	DOC_ACRONYM_FILTER_REQUEST,
	DOC_DESCRIPTION_FILTER_REQUEST,
	CLEAR_ALL_DOC_FILTERS,
} from '@/store/modules/filtersByModules/docManagementFilters/actions';
import {
	CONTRACT_DETAIL_REQUEST,
	DOC_REQUEST,
	DOCUMENT_APPROVAL_REQUEST,
	CLEAR_DOC_STATE,
} from '@/store/modules/docManagement/actions';

export default {
	name: 'DocManagement',
	components: {
		Content,
		MobileFilter,
		WebFilter,
		FilterList,
		SpinnerIcon,
		DocManagementRow,
		ContainerTable,
		CourrierTable,
		DocManagementDetail,
		DefaultLoader,
		Pagination,
		ReprovalModal,
	},
	data: () => ({
		windowWidth: window.innerWidth,
		selectedTable: null,
		selectedInvoices: '',
		selectedContracts: '',
		selectedSequences: '',
		selectedPurchaseOrders: '',
		selectedContainers: '',
		selectedVehicles: '',
		selectedPods: '',
		selectedLoads: '',
		selectedAcronyms: '',
		selectedDescriptions: '',
		showRepprovalModal: false,
		reproveDocument: { type: null, code: null },
	}),
	created() {
		this.clearDocFilters();
		this.resetDocData();
		this.refreshFilters();
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
		this.updateCargoList();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		...mapActions('docManagementFilters', {
			getInvoiceFilter: DOC_INVOICE_FILTER_REQUEST,
			getContractFilter: DOC_CONTRACT_FILTER_REQUEST,
			getSequenceFilter: DOC_SEQUENCE_FILTER_REQUEST,
			getPurchaseFilter: DOC_PO_FILTER_REQUEST,
			getContainerFilter: DOC_CONTAINER_FILTER_REQUEST,
			getVesselFilter: DOC_VESSEL_FILTER_REQUEST,
			getPodFilter: DOC_POD_FILTER_REQUEST,
			getCargoFilter: DOC_CARGO_FILTER_REQUEST,
			getAcronymFilter: DOC_ACRONYM_FILTER_REQUEST,
			getDescriptionFilter: DOC_DESCRIPTION_FILTER_REQUEST,
			clearDocFilters: CLEAR_ALL_DOC_FILTERS,
		}),
		...mapActions('docManagement', {
			getContracts: CONTRACT_DETAIL_REQUEST,
			getDocList: DOC_REQUEST,
			documentApproval: DOCUMENT_APPROVAL_REQUEST,
			resetDocData: CLEAR_DOC_STATE,
		}),
		onResize() {
			this.windowWidth = window.innerWidth;
		},

		// REPROVAL METHODS
		handleModal() {
			this.showRepprovalModal = !this.showRepprovalModal;
		},
		handleConfirmReproval(note) {
			const body = {
				type: 'rejected',
				note: note,
			};

			const { document, invoiceNumber } = this.reproveDocument;

			this.documentApproval({ vm: this, body: body, code: document.code }).then((res) => {
				if (res.status === 200) {
					this.getContracts({
						vm: this,
						invoiceNumber: invoiceNumber,
					});

					this.reproveDocument = { type: null, code: null };

					this.$toastr.s(this.$t('modules.doc_management.reproval_modal.repproved'));
					this.handleModal();
				}
			});
		},
		documentToReprove(document) {
			this.reproveDocument = document;
			this.handleModal();
		},

		// GET METHODS
		updateCargoList() {
			this.normalizeFilters();
			this.getDocList({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				fromDate: this.initDateFilterCache.value.id,
				toDate: this.endDateFilterCache.value.id,
				invoice: this.selectedInvoices,
				contract: this.selectedContracts,
				sequence: this.selectedSequences,
				purchaseOrder: this.selectedPurchaseOrders,
				container: this.selectedContainers,
				vehicle: this.selectedVehicles,
				pod: this.selectedPods,
				load: this.selectedLoads,
				acronym: this.selectedAcronyms,
				description: this.selectedDescriptions,
			});
		},
		normalizeFilters() {
			this.selectedInvoices = '';
			this.invoiceFilterCache.value.forEach((item) => {
				this.selectedInvoices = item.id + ',' + this.selectedInvoices;
			});

			this.selectedContracts = '';
			this.contractFilterCache.value.forEach((item) => {
				this.selectedContracts = item.id + ',' + this.selectedContracts;
			});

			this.selectedSequences = '';
			this.sequenceFilterCache.value.forEach((item) => {
				this.selectedSequences = item.id + ',' + this.selectedSequences;
			});

			this.selectedPurchaseOrders = '';
			this.purchaseFilterCache.value.forEach((item) => {
				this.selectedPurchaseOrders = item.id + ',' + this.selectedPurchaseOrders;
			});

			this.selectedContainers = '';
			this.containerFilterCache.value.forEach((item) => {
				this.selectedContainers = item.id + ',' + this.selectedContainers;
			});

			this.selectedVehicles = this.vesselFilterCache.value?.id;

			this.selectedPods = this.podFilterCache.value?.id;

			this.selectedLoads = this.cargoFilterCache.value?.id;

			this.selectedAcronyms = this.acronymFilterCache.value?.id;

			this.selectedDescriptions = this.descriptionFilterCache.value?.id;
		},
		refreshFilters(param) {
			const filterObject = { vm: this };

			if (param != undefined && param != null) {
				filterObject['params'] = param.queryString;
				filterObject['skip'] = param.skip;
			} else {
				filterObject['params'] = null;
				filterObject['skip'] = false;
				filterObject['lastQuery'] = this.lastQueryString;
			}

			this.getInvoiceFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterDocManagement.setCachedInvoiceInput();
				}
			});
			this.getContractFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterDocManagement.setCachedContractInput();
				}
			});
			this.getSequenceFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterDocManagement.setCachedSequenceInput();
				}
			});
			this.getPurchaseFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterDocManagement.setCachedPurchaseInput();
				}
			});
			this.getContainerFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterDocManagement.setCachedContainerInput();
				}
			});
			this.getVesselFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterDocManagement.setCachedTransportInput();
				}
			});
			this.getPodFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterDocManagement.setCachedPodInput();
				}
			});
			this.getCargoFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterDocManagement.setCachedLoadInput();
				}
			});
			this.getAcronymFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterDocManagement.setCachedAcronymInput();
				}
			});
			this.getDescriptionFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterDocManagement.setCachedDescriptionInput();
				}
			});
		},
		changePage(param) {
			this.getDocList({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				limit: 10,
				page: param.params,
				fromDate: this.initDateFilterCache.value.id,
				toDate: this.endDateFilterCache.value.id,
				invoice: this.selectedInvoices,
				contract: this.selectedContracts,
				sequence: this.selectedSequences,
				purchaseOrder: this.selectedPurchaseOrders,
				container: this.selectedContainers,
				vehicle: this.selectedVehicles,
				pod: this.selectedPods,
				load: this.selectedLoads,
				acronym: this.selectedAcronyms,
				description: this.selectedDescriptions,
			});
		},

		// HELPERS
		closeAllTables(invoiceNumber) {
			this.selectedTable = invoiceNumber;

			if (this.docsList && this.docsList.length) {
				this.docsList.forEach((doc) => {
					if (invoiceNumber != doc.invoiceNumber) {
						this.$refs[`document-row-${doc.invoiceNumber}`][0].closeTable();
					}
				});
			}
		},
	},
	computed: {
		isWeb() {
			return this.windowWidth > 992;
		},
		...mapGetters('filters', ['companyFilter', 'headerCompanyFilterCache']),
		...mapGetters('docManagement', [
			'docsList',
			'docsListPage',
			'docsListMax',
			'isLoadingDocsList',
			'containerList',
			'isLoadingContainerList',
		]),
		...mapGetters('docManagementFilters', [
			'initDateFilterCache',
			'endDateFilterCache',
			'invoiceFilterCache',
			'contractFilterCache',
			'sequenceFilterCache',
			'purchaseFilterCache',
			'containerFilterCache',
			'vesselFilterCache',
			'podFilterCache',
			'cargoFilterCache',
			'acronymFilterCache',
			'descriptionFilterCache',
			'lastQueryString',
		]),
		pagination() {
			const pagination =
				this.isLoadingDocsList != 'empty'
					? { currentPage: this.docsListPage, totalPages: this.docsListMax }
					: { currentPage: 1, totalPages: 1 };

			return pagination;
		},
		loadIsDone() {
			return this.isLoadingDocsList === 'success';
		},
	},
	watch: {
		headerCompanyFilterCache(value) {
			if (value.value !== null) {
				this.clearDocFilters();
				this.resetDocData();
				this.refreshFilters();
				this.$refs.filterDocManagement.clearCachedFilters();
				this.normalizeFilters();
				this.getDocList({
					vm: this,
					companyId: this.headerCompanyFilterCache.value.id,
					fromDate: this.initDateFilterCache.value.id,
					toDate: this.endDateFilterCache.value.id,
					invoice: this.selectedInvoices,
					contract: this.selectedContracts,
					sequence: this.selectedSequences,
					purchaseOrder: this.selectedPurchaseOrders,
					container: this.selectedContainers,
					vehicle: this.selectedVehicles,
					pod: this.selectedPods,
					load: this.selectedLoads,
					acronym: this.selectedAcronyms,
					description: this.selectedDescriptions,
				});
			}
		},
		lastQueryString() {
			this.updateCargoList();
		},
	},
};
</script>

<style lang="scss">
@import 'DocManagement.scss';
</style>
