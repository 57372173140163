<template>
	<form class="form" id="recover-form" v-on:submit.prevent="recoverPassword">
		<div class="row">
			<div class="back-btn" @click="viewChangePassword()">
				<BackArrow />
			</div>
			<h1 class="form__title">
				{{ $t('modules.login.forgot_password_title') }}
			</h1>
		</div>
		<p class="form__text" v-html="$t('modules.login.forgot_password_text')"></p>
		<div class="field">
			<label class="field__label" for="login">E-mail</label>
			<input class="field__input" type="text" name="login" id="recover-input" v-model="email" />
		</div>
		<div class="actions variant">
			<button
				id="recover-btn"
				class="actions__btn"
				:disabled="isLoadingEmail"
				type="submit"
				v-if="!isLoadingEmail"
			>
				<p>{{ $t('buttons.next') }}</p>
				<LoginArrow />
			</button>
			<SpinnerIcon color="white" v-else />
		</div>
	</form>
</template>

<script>
import { mapActions } from 'vuex';
import { RECOVER_PASSWORD } from '@/store/common/auth/actions';
import LoginArrow from '@/components/shared/Icons/LoginArrow.vue';
import BackArrow from '@/components/shared/Icons/BackArrow.vue';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon.vue';

export default {
	name: 'RecoverForm',
	components: {
		LoginArrow,
		BackArrow,
		SpinnerIcon,
	},
	data: () => ({
		isLoadingEmail: false,
		email: null,
	}),
	mounted() {},
	methods: {
		...mapActions('auth', {
			recoverReq: RECOVER_PASSWORD,
		}),
		viewChangePassword() {
			this.$emit('viewChangePassword');
		},
		recoverPassword() {
			if (!this.validEmail(this.email)) {
				return this.$toastr.e(this.$t('errors.email_invalid'));
			}

			this.isLoadingEmail = true;

			this.recoverReq({ vm: this, body: { email: this.email } })
				.then((res) => {
					this.isLoadingEmail = false;

					if (res.status == 201) {
						this.isLoadingEmail = false;
						this.$emit('showMessageScreen');
					}
				})
				.catch(() => {
					this.isLoadingEmail = false;
				});
		},
		validEmail(param) {
			const reg = /\S+@\S+\.\S+/;
			return reg.test(param);
		},
	},
	computed: {},
};
</script>

<style lang="scss" scoped>
@import 'RecoverForm.scss';
</style>
