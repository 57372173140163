<template>
	<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M13.1367 2.56049C13.3956 2.56049 13.6055 2.77035 13.6055 3.02924V5.84174C13.6055 6.10062 13.3956 6.31049 13.1367 6.31049H10.3242C10.0653 6.31049 9.85547 6.10062 9.85547 5.84174C9.85547 5.58285 10.0653 5.37299 10.3242 5.37299H12.668V3.02924C12.668 2.77035 12.8778 2.56049 13.1367 2.56049Z"
			fill="#AAC3DB"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.5 2.81299C6.88462 2.81299 6.27528 2.93431 5.70684 3.17001C5.13839 3.40571 4.62199 3.75117 4.18717 4.18662C4.00424 4.36982 3.70745 4.37003 3.52425 4.18711C3.34106 4.00418 3.34085 3.70739 3.52377 3.5242C4.04566 3.00154 4.66548 2.58691 5.34775 2.304C6.03003 2.0211 6.76139 1.87549 7.5 1.87549C8.23861 1.87549 8.96996 2.0211 9.65224 2.304C10.3344 2.58685 10.9541 3.00137 11.4759 3.52388L13.4677 5.50979C13.651 5.69258 13.6515 5.98938 13.4687 6.17271C13.2859 6.35603 12.9891 6.35647 12.8058 6.17368L10.8128 4.18662C10.378 3.75117 9.86161 3.40571 9.29316 3.17001C8.72472 2.93431 8.11538 2.81299 7.5 2.81299Z"
			fill="#AAC3DB"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.39453 9.15814C1.39453 8.89926 1.6044 8.68939 1.86328 8.68939H4.67578C4.93466 8.68939 5.14453 8.89926 5.14453 9.15814C5.14453 9.41703 4.93466 9.62689 4.67578 9.62689H2.33203V11.9706C2.33203 12.2295 2.12216 12.4394 1.86328 12.4394C1.6044 12.4394 1.39453 12.2295 1.39453 11.9706V9.15814Z"
			fill="#AAC3DB"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.53134 8.82718C1.71413 8.64385 2.01092 8.64341 2.19425 8.8262L4.18717 10.8133C4.62199 11.2487 5.13839 11.5942 5.70684 11.8299C6.27528 12.0656 6.88462 12.1869 7.5 12.1869C8.11538 12.1869 8.72472 12.0656 9.29316 11.8299C9.86161 11.5942 10.378 11.2487 10.8128 10.8133C10.9958 10.6301 11.2926 10.6298 11.4757 10.8128C11.6589 10.9957 11.6592 11.2925 11.4762 11.4757C10.9543 11.9983 10.3345 12.413 9.65224 12.6959C8.96996 12.9788 8.23861 13.1244 7.5 13.1244C6.76139 13.1244 6.03003 12.9788 5.34775 12.6959C4.66565 12.413 4.04598 11.9985 3.52417 11.4761L3.52377 11.4757L1.53231 9.49009C1.34899 9.3073 1.34855 9.0105 1.53134 8.82718Z"
			fill="#AAC3DB"
		/>
		<path
			d="M11.4759 3.52388C10.9541 3.00137 10.3344 2.58685 9.65224 2.304C8.96996 2.0211 8.23861 1.87549 7.5 1.87549C6.76139 1.87549 6.03003 2.0211 5.34776 2.304C4.66548 2.58691 4.04566 3.00154 3.52377 3.5242C3.34085 3.70739 3.34106 4.00418 3.52425 4.18711C3.70745 4.37003 4.00424 4.36982 4.18717 4.18662C4.62199 3.75117 5.13839 3.40571 5.70684 3.17001C6.27528 2.93431 6.88462 2.81299 7.5 2.81299C8.11538 2.81299 8.72472 2.93431 9.29316 3.17001C9.86161 3.40571 10.378 3.75117 10.8128 4.18662L12.8058 6.17368C12.9891 6.35647 13.2859 6.35603 13.4687 6.17271C13.6515 5.98938 13.651 5.69258 13.4677 5.50979L11.4759 3.52388ZM11.4759 3.52388V3.52388ZM13.6055 3.02924C13.6055 2.77035 13.3956 2.56049 13.1367 2.56049C12.8778 2.56049 12.668 2.77035 12.668 3.02924V5.37299H10.3242C10.0653 5.37299 9.85547 5.58285 9.85547 5.84174C9.85547 6.10062 10.0653 6.31049 10.3242 6.31049H13.1367C13.3956 6.31049 13.6055 6.10062 13.6055 5.84174V3.02924ZM1.86328 8.68939C1.6044 8.68939 1.39453 8.89926 1.39453 9.15814V11.9706C1.39453 12.2295 1.6044 12.4394 1.86328 12.4394C2.12216 12.4394 2.33203 12.2295 2.33203 11.9706V9.62689H4.67578C4.93466 9.62689 5.14453 9.41703 5.14453 9.15814C5.14453 8.89926 4.93466 8.68939 4.67578 8.68939H1.86328ZM2.19425 8.8262C2.01092 8.64341 1.71413 8.64385 1.53134 8.82718C1.34855 9.0105 1.34899 9.3073 1.53231 9.49009L3.52377 11.4757L3.52417 11.4761C4.04598 11.9985 4.66565 12.413 5.34775 12.6959C6.03003 12.9788 6.76139 13.1244 7.5 13.1244C8.23861 13.1244 8.96996 12.9788 9.65224 12.6959C10.3345 12.413 10.9543 11.9983 11.4762 11.4757C11.6592 11.2925 11.6589 10.9957 11.4757 10.8128C11.2926 10.6298 10.9958 10.6301 10.8128 10.8133C10.378 11.2487 9.86161 11.5942 9.29316 11.8299C8.72472 12.0656 8.11538 12.1869 7.5 12.1869C6.88462 12.1869 6.27528 12.0656 5.70684 11.8299C5.13839 11.5942 4.62199 11.2487 4.18717 10.8133L2.19425 8.8262Z"
			stroke="#AAC3DB"
			stroke-width="0.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<script>
export default {
	name: 'RefreshIcon',
};
</script>
