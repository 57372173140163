<template>
	<div class="header-container">
		<div class="header-container__details" @mouseleave="closeDropdown">
			<h1 class="header-container__title" v-if="!isContractDetail" @click="back">
				<BackArrow color="#234057" v-if="hideBackButton" /> {{ title }}
			</h1>
			<div class="header-contract-detail" v-else>
				<div class="header-contract-detail__info">
					<span class="header-contract-detail__info__title">
						{{ $t('header.header_contracts') }}
					</span>
					<span class="header-contract-detail__info__value"> {{ values.contracts }} </span>
				</div>
				<div class="header-contract-detail__info">
					<span class="header-contract-detail__info__title">
						{{ $t('header.header_sequences') }}
					</span>
					<span class="header-contract-detail__info__value"> {{ values.sequences }} </span>
				</div>
				<div class="header-contract-detail__info">
					<span class="header-contract-detail__info__title"> {{ $t('header.header_loads') }} </span>
					<span class="header-contract-detail__info__value"> {{ values.loads }} </span>
				</div>
				<div class="header-contract-detail__info">
					<span class="header-contract-detail__info__title"> {{ $t('header.header_po') }} </span>
					<span class="header-contract-detail__info__value"> {{ values.po }} </span>
				</div>
			</div>
			<div class="row">
				<CompanyInput
					v-if="!hideCompanyInput"
					ref="companiesHeader"
					:allowDocInstruModal="allowDocInstruModal"
					:isWeb="isWeb"
					@handleDocInstrucModal="handleDocInstrucModal"
				/>
				<UserInfo ref="userInfo" />
				<LangSelector ref="langSelector" />
			</div>
		</div>
		<DocInstructionModal
			v-show="allowDocInstruModal && handleDocInstruModal"
			:isWeb="isWeb"
			@handleConfirm="handleConfirm"
			@handleClose="handleDocInstrucModal"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	HEADER_COMPANY_FILTER_REQUEST,
	SAVE_HEADER_COMPANY_FILTER,
	DEFINE_DEFAULT_HEADER_COMPANY,
} from '@/store/common/filters/actions';
import { CLEAR_CONTRACT_STATE } from '@/store/modules/docInstruction/actions';

import UserInfo from '@/components/shared/UserInfo/UserInfo.vue';
import LangSelector from '@/components/shared/LangSelector/LangSelector.vue';
import CompanyInput from '@/components/shared/Header/Inputs/CompanyInput/CompanyInput.vue';
import BackArrow from '@/components/shared/Icons/BackArrow.vue';
import DocInstructionModal from '@/components/shared/Header/DocInstructionModal/DocInstructionModal.vue';

export default {
	name: 'Header',
	components: {
		UserInfo,
		LangSelector,
		CompanyInput,
		BackArrow,
		DocInstructionModal,
	},
	props: {
		isWeb: {
			required: true,
		},
		hasBackButton: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		title: '',
		allowDocInstruModal: false,
		handleDocInstruModal: false,
	}),
	mounted() {
		this.refreshFilters();
	},
	methods: {
		...mapActions('filters', {
			getHeaderCompanyFilter: HEADER_COMPANY_FILTER_REQUEST,
			saveCompanyFilter: SAVE_HEADER_COMPANY_FILTER,
			setDefaultCompany: DEFINE_DEFAULT_HEADER_COMPANY,
		}),
		...mapActions('docInstruction', {
			clearContractStore: CLEAR_CONTRACT_STATE,
		}),
		// DOC INSTRUC MODAL HANDLERS
		handleConfirm() {
			this.$refs.companiesHeader.handleDocInstrucAction('allowed');
			this.handleDocInstrucModal(false);
		},
		handleDocInstrucModal(value) {
			this.handleDocInstruModal = value;
		},

		// GENERIC HANDLERS
		closeDropdown() {
			this.$refs.userInfo.closeDropdown();
			this.$refs.langSelector.closeDropdown();
		},
		back() {
			if (this.hideBackButton) {
				this.$router.go(-1);
				return;
			}
			return;
		},

		// API METHODS
		refreshFilters() {
			this.getHeaderCompanyFilter({ vm: this }).then((res) => {
				if (this.$refs.companiesHeader !== undefined && res.status == 200) {
					this.$refs.companiesHeader.setCachedInput();
				}
			});
		},
		setCompany() {
			const localStorageCache = localStorage.getItem('headerCompany');

			if (localStorageCache && this.headerCompanyFilterCache.value === null) {
				this.getHeaderCompanyFilter({ vm: this }).then((res) => {
					this.setDefaultCompany();
				});
			}
		},
	},
	computed: {
		...mapGetters('contractDetail', ['contracts', 'sequences', 'loads', 'po']),
		...mapGetters('filters', ['headerCompanyFilterCache']),
		...mapGetters('docInstruction', ['getStep']),
		isContractDetail() {
			return this.$route.path.includes('contractDetail') ? true : false;
		},
		values() {
			return {
				contracts: this.contracts,
				sequences: this.sequences,
				loads: this.loads,
				po: this.po,
			};
		},
		hideCompanyInput() {
			const routeName = this.$route.name;
			return (
				routeName == 'header.dashboard' ||
				routeName == 'header.user_management' ||
				routeName == 'header.contract_management' ||
				routeName == 'header.contract_details' ||
				routeName == 'header.load_management'
			);
		},
		hideBackButton() {
			const routeName = this.$route.name;
			return routeName == 'header.new_template' || routeName == 'header.edit_template';
		},
		docInstructionRoutes() {
			return ['header.doc_instruction', 'header.new_template', 'header.edit_template'];
		},
	},
	watch: {
		$route: {
			handler(route) {
				this.title = this.$t(`${route.name}`);
				this.handleDocInstModal = false;
				this.setCompany();

				if (!this.docInstructionRoutes.includes(route.name)) {
					this.clearContractStore();
				}
			},
			deep: true,
			immediate: true,
		},
		getStep(value) {
			const route = this.$route.name;

			if (this.docInstructionRoutes.includes(route) && value > 1) {
				this.allowDocInstruModal = true;
			} else {
				this.allowDocInstruModal = false;
			}
		},
		'$i18n.locale'() {
			this.title = this.$t(`${this.$route.name}`);
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'Header.scss';
</style>
