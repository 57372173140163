<template>
	<div class="map-wrapper">
		<div v-if="showDetails" class="map-card">
			<div class="map-card__head">
				<div class="map-card__info">
					<div class="map-card__info-title">{{ details.label }}</div>
					<div class="map-card__info-subtitle">
						Latitude: {{ details.latitude }} S Longitude: {{ details.longitude }} W
					</div>
				</div>
				<div class="map-card__icon" @click="hideData(details)">
					<MinifyIcon />
				</div>
			</div>
			<div class="map-card__body">
				<table class="map-card__table">
					<thead class="map-card__table-head">
						<th>Veículo</th>
						<th>Carga</th>
						<th>Contrato</th>
						<th>SKU</th>
					</thead>
					<tr
						v-for="contract in markerDetail"
						:key="contract.loadNumber"
						class="map-card__table-body"
					>
						<th>{{ contract.vehicleNumber }}</th>
						<th>{{ contract.loadNumber }}</th>
						<th>{{ contract.contractNumber }}</th>
						<th>{{ contract.sku }}</th>
					</tr>
				</table>
			</div>
		</div>
		<MglMap
			mapStyle="mapbox://styles/mapbox/streets-v11"
			container="map"
			:accessToken="accessToken"
			:zoom="zoom"
			:attributionControl="false"
			:showZoom="true"
			:preserveDrawingBuffer="true"
			:center="center"
			@load="onMapLoaded"
		>
			<MglMarker
				v-for="marker in mapMarkers"
				:key="marker.portName"
				:coordinates="[marker.longitude, marker.latitude]"
				@click="showData(marker)"
			>
				<MapMarker
					:is-ship="isShip"
					pinType="destination"
					:count="marker.qtdCargas"
					slot="marker"
					:ref="marker.portName"
				/>
			</MglMarker>
		</MglMap>
	</div>
</template>

<script>
import { MglMap, MglMarker, MglPopup, MglNavigationControl } from 'vue-mapbox';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapMarker from '@/components/modules/LoadManagement/MapMarker/MapMarker.vue';
import MinifyIcon from '@/components/shared/Icons/MapIcons/MinifyIcon.vue';
import { mapGetters, mapActions } from 'vuex';
import {
	MAP_MARKERS_REQUEST,
	MARKERS_DETAIL_REQUEST,
} from '@/store/modules/loadManagement/actions';

export default {
	name: 'Map',
	props: {
		isShip: {
			type: Boolean,
			default: true,
		},
	},
	components: {
		MapMarker,
		MinifyIcon,
		MglMap,
		MglMarker,
		MglPopup,
		MglNavigationControl,
	},
	data() {
		return {
			accessToken:
				'pk.eyJ1IjoibWFkZW1hdGV1c2dydWJlciIsImEiOiJja2hnY3NhajIwN3R5Mnhxa2szemFzbWVuIn0.gGCSrRmfvMcuLMO6ecGJBw',
			mapbox: null,
			childrenCoords: [],
			center: [-46.3041324, -23.9660408],
			showDetails: false,
			details: {
				label: '',
				qtdCargas: '',
				latitude: null,
				longitude: null,
			},
			currentFilterContracts: '',
			currentPurchaseOrder: '',
			currentLoad: '',
			currentTransport: '',
			currentContainer: '',
			currentProduct: '',
			zoom: 1,
		};
	},
	mounted() {
		this.normalizeFilters();
		this.getMarkers({
			vm: this,
			companyId: this.headerCompanyFilterCache.value.id,
			contractNumberSequence: this.currentFilterContracts,
			purchaseOrder: this.currentPurchaseOrder,
			loadNumber: this.currentLoad,
			vessel: this.currentTransport,
			container: this.currentContainer,
			product: this.currentProduct,
			expectedEtbDate: this.etsFilterCache.value.id,
			realAtbDate: this.etaFilterCache.value.id,
		});
	},
	methods: {
		...mapActions('loadManagement', {
			getMarkers: MAP_MARKERS_REQUEST,
			getMarkerDetail: MARKERS_DETAIL_REQUEST,
		}),
		onMapLoaded(event) {
			this.map = event.map;

			if (this.isLoadingMapMarkers == 'success') {
				this.map.jumpTo({ center: [this.mapMarkers[0].longitude, this.mapMarkers[0].latitude] });
			} else {
				this.map.jumpTo({ center: this.center });
			}
		},
		showData(marker) {
			document.querySelectorAll('.marker.active').forEach((el) => {
				el.classList.remove('active');
			});

			this.map.flyTo({ center: [marker.longitude - 0.005, marker.latitude], zoom: 15 });
			this.$refs[marker.portName][0].$el.classList.add('active');

			this.showDetails = true;

			this.details.label = marker.portName;
			this.details.qtdCargas = marker.qtdCargas;
			this.details.latitude = marker.latitude;
			this.details.longitude = marker.longitude;

			this.getMarkerDetail({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				port: marker.portName,
				contractNumberSequence: this.currentFilterContracts,
				purchaseOrder: this.currentPurchaseOrder,
				loadNumber: this.currentLoad,
				vessel: this.currentTransport,
				container: this.currentContainer,
				product: this.currentProduct,
				expectedEtbDate: this.etsFilterCache.value.id,
				realAtbDate: this.etaFilterCache.value.id,
			});
		},
		hideData(marker) {
			this.map.flyTo({ center: [marker.longitude, marker.latitude] });
			this.$refs[marker.label][0].$el.classList.remove('active');
			this.showDetails = false;
			this.details = {}; //{ id: '', label: '', coordinates: '', contratos: [] };
		},
		normalizeFilters() {
			this.currentFilterContracts = '';
			this.contractsFilterCache.value.forEach((item) => {
				this.currentFilterContracts = item.id + ',' + this.currentFilterContracts;
			});

			this.currentPurchaseOrder = '';
			this.poFilterCache.value.forEach((item) => {
				this.currentPurchaseOrder = item.id + ',' + this.currentPurchaseOrder;
			});

			this.currentLoad = '';
			this.loadFilterCache.value.forEach((item) => {
				this.currentLoad = item.id + ',' + this.currentLoad;
			});

			this.currentTransport = '';
			this.transportFilterCache.value.forEach((item) => {
				this.currentTransport = item.id + ',' + this.currentTransport;
			});

			this.currentContainer = '';
			this.containerFilterCache.value.forEach((item) => {
				this.currentContainer = item.id + ',' + this.currentContainer;
			});

			this.currentProduct = '';
			if (this.productFilterCache.value) {
				this.currentProduct = this.productFilterCache.value.id || '';
			}
		},
	},
	computed: {
		...mapGetters('filters', ['companyFilter', 'headerCompanyFilterCache']),
		...mapGetters('loadManagement', [
			'mapMarkers',
			'isLoadingMapMarkers',
			'markerDetail',
			'isLoadingMarkerDetail',
		]),
		...mapGetters('loadManagementFilters', [
			'contractsFilterCache',
			'poFilterCache',
			'loadFilterCache',
			'transportFilterCache',
			'containerFilterCache',
			'productFilterCache',
			'etsFilterCache',
			'etaFilterCache',
			'lastQueryString',
		]),
	},
	watch: {
		lastQueryString() {
			this.showDetails = false;
			this.normalizeFilters();
			this.getMarkers({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				contractNumberSequence: this.currentFilterContracts,
				purchaseOrder: this.currentPurchaseOrder,
				loadNumber: this.currentLoad,
				vessel: this.currentTransport,
				container: this.currentContainer,
				product: this.currentProduct,
				expectedEtbDate: this.etsFilterCache.value.id,
				realAtbDate: this.etaFilterCache.value.id,
			});
		},
		isLoadingMapMarkers(value) {
			this.mapMarkers.sort(function (a, b) {
				return a.qtdCargas < b.qtdCargas ? -1 : a.qtdCargas > b.qtdCargas ? 1 : 0;
			});
			if (this.map) {
				if (value == 'success') {
					this.map.flyTo({ center: [this.mapMarkers[0].longitude, this.mapMarkers[0].latitude] });
				}
			}
		},
		headerCompanyFilterCache() {
			this.map.flyTo({ center: [-46.3041324, -23.9660408], zoom: 1 });
			this.showDetails = false;
			this.details = {};
		},
	},
};
</script>

<style lang="scss">
@import 'Map.scss';
</style>
