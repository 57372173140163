import tableUS from './contractTable/table-us';

export default {
	buttons: {
		cancel: 'Cancel',
		login: 'Login',
		next: 'Next',
		ok: 'OK',
		save: 'Save',
		save_password: 'Save password',
		filter: 'Select',
		filters: 'Select',
		clear_filter: 'Clear Selections',
		add: 'Add',
		delete: 'Delete',
		inative: 'Disable',
		edit_column: 'Edit column',
		confirm: 'Confirm',
		back: 'Back',
		keep_inactive: 'Inactive',
		keep_active: 'Activate',
		previous: 'Previous',
		seeMore: 'See More',
		seeLess: 'See less',
		continue: 'Continue',
		disable: 'Disable',
		active: 'Activate',
		new_template: 'New template',
		save_template: 'Save Template',
		change_template: 'Change Template',
		add_bank: 'Add Bank',
		notify: 'Notify',
		edit: 'Edit',
		disapprove: 'Disapprove',
		remove: 'Remove',
	},
	errors: {
		login_required: 'Login required.',
		password_required: 'Password required.',
		empty_data: 'Empty data',
		dashboard_empty_data: 'No updates',
		fill_fields: 'Fill in all the fields.',
		email_invalid: 'Invalid email',
		passwords_rules:
			'Please check if the password has 8 characters. it must have at least one capital letter, one lower case letter, one number and one special character.',
		passwords_dismatch: 'Wrong password',
		fixed_column: 'Column cannot be disabled.',
		no_options: 'No option found.',
		no_items: 'No items found',
		get_items_error: 'Error when loading items',
		documents_error: 'No documents found!',
		template_name_required: 'Template name is required',
	},
	sidebar: {
		user_management: 'Users Management',
		contract_management: 'Contracts Management',
		contract_details: 'Contract Details',
		dashboard: 'Dashboard',
		load_management: 'Loads Management',
		doc_management: 'Documents Management',
		financial_management: 'Financial Management',
		doc_instruction: 'Documentary Instruction',
	},
	header: {
		user_management: 'User Management',
		contract_management: 'Contracts Management',
		contract_details: 'Contract Details',
		header_contracts: 'Contracts',
		header_sequences: 'Sequence',
		header_loads: 'Load number',
		header_po: 'Customer PO',
		dashboard: 'Dashboard',
		load_management: 'Loading Management',
		customer_company: 'Company/Customer',
		doc_management: 'Documents Management',
		financial_management: 'Financial Management',
		doc_instruction: 'Documentary Instruction',
		new_template: 'New Template',
		edit_template: 'Edit Template',
	},
	months: {
		jan: 'Jan',
		feb: 'Feb',
		mar: 'Mar',
		apr: 'Apr',
		may: 'May',
		jun: 'Jun',
		jul: 'Jul',
		aug: 'Aug',
		sep: 'Sep',
		oct: 'Oct',
		nov: 'Nov',
		dec: 'Dec',
	},
	contract_status: {
		contract: 'Contract Created',
		load: 'Loading number',
		process: 'Loading planned',
		container: 'Container loaded',
		invoice: 'Shipped / invoiced',
		finished: 'Delivered',
	},
	contract_step: {
		creation: 'Contract under creation',
		released_seara_restriction: 'Released - Seara Restriction',
		released_customer_restriction: 'Released - Customer Restriction',
		released: 'Released',
		programmed: 'Programmed',
		planned: 'Planned',
		billed: 'Loaded',
		issued_document: 'HC Issued',
		gate_in: 'Gate In date pol',
		pre_payment_pending: 'Awaiting prepayment',
		rid: 'Ready to be shipped',
		draft_bl: 'Bl draft sent',
		boarded: 'Shipped',
		documentation_issued: 'Documentation Issued',
		documentation_sended: 'Documentation Sent',
		documentation_delivered: 'Documentation Delivered',
		shipped_origin: 'Shipped',
		transshipment_port: 'Transshipment Port',
		landed_destination: 'Discharged pod',
		gate_out: 'Gate Out At Destination',
		empty_return: 'Empty Returned',
		opened: 'Opened',
		opened_delay: 'Overdue',
		partial_paid: 'Partially Paid',
		total_paid: 'Fully Paid',
		finished: 'Finished',
		processing: 'In progress',
		in_production: 'In Production',
		in_loading: 'Loading',
		delivered_in_pod: 'Delivered POD',
	},
	modules: {
		login: {
			login_label: 'Login',
			password_label: 'Password',
			forgot_password: 'Forgot your password?',
			forgot_password_title: 'Forgot my password',
			forgot_password_text:
				'Forgot your password? Inform your e-mail to get a link to reset your password.',
			email_sended: 'E-mail sent to create your new password.',
			code_sended: 'Security code sent',
			verify_email:
				'Please check your e-mail and insert the security code (6 digits) to complete your login.',
		},
		resetPassword: {
			new_password_label: 'New password',
			confirm_password_label: 'Confirm new password',
			new_password_title: 'New password',
			new_password_text: 'Now you can change your password again:',
			password_changed_succesfully: 'Password changed succesfully!',
			rules_title: 'Requirement for the new password',
			rule_uppercase: 'At least one capital letter',
			rule_lowercase: 'At least one lowercase letter',
			rule_number: 'At least one number',
			rule_length: 'At least 8 characters ',
			rule_special: 'At least one one special character (!, @, #, $, %, *, ., etc).',
		},
		user_management: {
			name_placeholder: 'User name',
			email_placeholder: 'E-mail',
			company_placeholder: 'Company name',
			status_placeholder: 'User Status',
			profiles: {
				client: 'Customer',
				intermediary: 'Intermediary',
				internal: 'Internal',
			},
			status: {
				active: 'Active',
				suspended: 'Inactive',
			},
			requests_messages: {
				user_created: 'User created succesfully!',
				user_updated: 'User updated succesfully',
				user_deleted: 'User successfully deactivated.',
				user_reactived: 'User successfully reactivated.',
			},
			modal: {
				new_user: 'Register new user',
				edit_user: 'Edit User',
				delete_question: 'Are you sure you want to disable this user?',
				reactive_question: 'Are you sure you want to reactivate this user?',
				name_label: 'Name',
				name_placeholder: 'Complete name',
				company_label: 'Full name',
				company_placeholder: 'Company (fantasy name)',
				email_label: 'E-mail',
				email_placeholder: 'Your e-mail',
				phone_label: 'Mobile number',
				phone_placeholder: 'Telephone number',
				profile_label: 'Profile',
				profile_placeholder: 'User profile',
				status_label: 'Status',
				status_placeholder: 'User status',
				company_manager_label: 'Companies under management',
				company_manager_placeholder: 'Enter the company name',
				confirmation_active:
					'Do you want to activate this user? when activating the user, he/she will receive a welcome e-mail. do you want to proceed?',
			},
			table: {
				name: 'Name',
				email: 'E-mail',
				code: 'Code',
				status: 'Status',
			},
		},
		contract_management: {
			table: tableUS,
			filter: {
				start_date: 'Initial Date:',
				end_date: 'Final Date:',
				contract_start_date: 'Contract Initial Date:',
				contract_end_date: 'Contract Final Date:',
				contract_placeholder: 'Contract',
				company_placeholder: 'Company',
				transport_placeholder: 'Vessel/truck',
				product_placeholder: 'Product/item',
				purchaseOrder_placeholder: 'PO',
				market_placeholder: 'Market',
			},
			column_modal: {
				title_modal: 'Edit Columns',
				header_columns: 'Contract',
				details_columns: 'Detail per load',
				active_status: 'Active',
				disable_status: 'Inactive',
				exit_question: 'Do you want to close without saving last changes?',
			},
			requests_messages: {
				preferences_updated: 'Columns saved successfuly.',
			},
		},
		contract_detail: {
			card: {
				title_tracking: 'Contract management',
				title_contract: 'Contract',
				title_sequence: 'Sequence',
				title_load: 'Load Number',
				title_document: 'Documents management',
			},
			contract: {
				companyName: 'Company',
				competenceMonth: 'Competence mm/yyyy',
				contractDate: 'Contract Date',
				contractNumber: 'Contract number',
				incoterm: 'Incoterm',
				market: 'Market',
				paymentMethod: 'Payment Terms',
				pod: 'Pod',
				priceType: 'Currency',
				purchaseOrder: 'PO',
				purchaseOrderClient: 'Customer PO',
				releaseDate: 'Contract Creation On',
				totalPendingVolume: 'Pending Volume to be loaded',
				totalVolume: 'Total Volume',
				traderName: 'Trader',
				approved: 'Approved',
				disapproved: 'Disapproved',
				approve: 'Approve',
				disapprove: 'Disapprove',
				preposition: 'at',
			},
			sequence: {
				brand: 'Brand',
				cneCode: 'Letter of Credit Code',
				contractSequence: 'Sequence',
				dsInternationalOfc: 'Description of goods',
				endShipDate: 'Last Shipment Date',
				groupingDescription: 'Batch',
				permissionImt: 'Import license',
				permissionImtDate: 'Expiry date (license)',
				releaseSequenceDate: 'Sequence release date',
				sku: 'Product Name',
				startShipDate: 'First shipment date',
				statusSequence: 'Sequence status',
				totalVolume: 'Volume',
				unitPrice: 'Unit Price',
				weeksToShip: 'Shipment Week',
				initials: 'Product code',
			},
			load: {
				invoicedDate: 'Invoice Date(plant/storage)',
				loadNumber: 'Load Number',
				loadPlace: 'Loading place',
				productionDate: 'Production Date',
				totalGrossWeight: 'Gross Weight',
				totalKg: 'Total Weight',
				totalVolume: 'Total Volume',
			},
			documents: {
				awbCourier: 'Courier Awb',
				billLanding: 'BL/CRT/AWB',
				billLandingDate: 'BL/CRT/AWB Date',
				blDate: 'BL/CRT/AWB Date',
				courierDate: 'Courier date',
				csiIssuanceDate: 'Hc date',
				invoiceNumber: 'Invoice',
				courierCompany: 'Courier Company',
				bankAwbCourier: 'Courier awb (Bank)',
				bankCourierDate: 'Courier date (Bank)',
				bankCourierCompany: 'Courier Company (Bank)',
			},
			table: {
				title_international: 'Vessel / truck',
				title_maritime: 'Shipping company',
				title_mercosul: 'Mercosul transport / rode',
			},
			internationalTransport: {
				boardingType: 'Boarding type',
				origin: 'Port of loading',
				destination: 'Port of destination',
				expectedEtdDate: 'ETS POL',
				expectedEtbDate: 'ATS POL',
				realAtdDate: 'ETA POD',
				realAtbDate: 'ETA POD',
			},
			maritimeTransport: {
				provider: 'Carrier',
				vessel: 'Vessel / truck',
				container: 'Container',
				bookingNumber: 'Booking number',
			},
			mercosurTransport: {
				shippingCompany: 'Carrier / transportation company',
				vehiclePlate: 'Vessel voyage / truck license plate nr',
				localization: 'Localization / place',
				borderCrossingDate: 'Boading cross date',
			},
		},
		dashboard: {
			table: {
				title: 'Latest status updates',
				contract: 'Contract',
				sequence: 'Sequence',
				load: 'Load Number',
				transport: 'Vessel/Truck/Vehicle',
				invoice: 'Invoice',
				code: 'Product Code',
				status: 'Status',
				volume: 'Net weight',
			},
			charts: {
				analysis_title: 'Under analysis',
				finance_title: 'Financial Management',
				rounded: {
					in_process: 'Processing',
					release_retrictions: ['Sold With Customer ', 'restrictions'],
					released: 'Released To Operation',
					open: 'Openned',
					open_delay: ['Overdue ', ''],
					partial_paid: 'Partially Paid',
					paid: 'Fully Paid',
					finished: 'Finished',
				},
			},
			operation_chart: {
				operation_title: 'In Operation',
				in_production: 'In production',
				in_charging: 'Loading',
				gate_in: 'Gate In Pol',
				wait_prepayment: 'Awaiting Prepayment',
				embedded_doc: 'Documents Delivered',
				sent_doc: 'Documents Sent',
				delivered: 'Shipped',
				delivered_pod: 'Delivered POD',
				gate_out: 'Gate Out POD',
				empty_returned: 'Empty Returned',
			},
		},
		load_management: {
			header: {
				truck: 'Truck',
				ship: 'Ship',
			},
			filter: {
				contract: 'Contract / Seq.',
				po: 'Customer Po',
				load: 'Load Number',
				transport: 'Vessel / Truck',
				container: 'Container',
				product: 'Item - Description',
				ets: 'Ets / Ats - Pol',
				eta: 'ETA POD',
			},
			sections: {
				inProduction: 'Being Produced',
				loading: 'Goods Being Loaded In Ctnr',
				gateInOrigin: 'Gate In Pol',
				shipped: 'Shipped',
				deliveredToPOD: 'Delivered POD',
				gateOutDestination: 'Gate Out Pod',
				deliveredToDestination: 'Delivered To Destination',
				border: 'Border',
			},
			cargoTable: {
				cargo: 'Load Number',
				container: 'Container',
				invoice: 'Invoice',
				vehicle: 'Vessel / Truck',
				POD: 'POD',
				gateIn: 'Gate In Date',
				ets: 'Eta / Ats Pol',
				eta: 'ETA POD',
				gateOut: 'Gate Out Pod',
				netWeight: 'Net Weight',
			},
			contractTable: {
				sequence: 'Sequence',
				acronym: 'Item',
				description: 'Description',
				brand: 'Brand',
				sif: 'SIF',
				volume: 'Number of Cartons',
				weight: 'Net Weight',
			},
			map: {
				contracts: 'Contracts',
				products: 'Products',
				loads: 'Loads',
				weigth: 'Net Weigth',
				awaiting_payment: 'Awaiting Prepayment',
			},
		},
		financial_management: {
			titles: {
				pre_payment: 'Prepayment',
				invoice: 'Invoice',
				overdue: 'Overdue',
				settle: 'Payment Due',
				credit: 'Credit Situation',
				limit: 'Current credit limit',
				balance_available: 'Balance available',
			},
			filter: {
				market: 'Market',
				init_date: 'Initial Date',
				end_date: 'Last Date',
				currency: 'Currency',
				status: 'Status',
			},
			status: {
				paid: 'PAID',
				awaiting_paid: 'AWAITING PAYMENT',
				overdue: 'OVERDUE',
			},
			table: {
				due_date: 'Due Date',
				contract: 'Contract',
				invoice: 'Invoice',
				total_invoice: 'Total Amount',
				po: 'PO',
				currency: 'Currency',
				status: 'Status',
				total: 'Total Amount',
				payment: 'Payment',
				balance: 'Balance',
			},
			currency: {
				dolar: 'DOLLAR',
				euro: 'EURO',
				yen: 'YEN',
			},
		},
		doc_management: {
			filter: {
				init_date: 'Initial Date:',
				end_date: 'Last Date:',
				invoice: 'Invoice',
				contract: 'Contract',
				sequence: 'Sequence',
				po: 'PO',
				container: 'Container',
				transport: 'Vessel / Truck',
				pod: 'POD',
				load: 'Load',
				acronym: 'Item',
				description: 'Description Of Goods',
			},
			doc_row: {
				invoice: 'Invoice',
				vehicle: 'Vessel / Truck',
				pol: 'POL',
				atsPol: 'Ats Pol',
				pod: 'POD',
				etaPod: 'ETA POD',
				plTon: 'Volume',
				value: 'Total Value',
				download: 'Download',
				payment_status: 'Payment Status',
				approval_status: 'Approval Status',
				shipment_status: 'Documents Sending Status',
			},
			doc_detail: {
				contract: 'Contract',
				condition: 'Payment Term',
				term: 'Payment Condition',
				type: 'Document (Specific)',
				publication: 'Publishing Date',
				responsible: 'Responsible',
				approval: 'Approval date',
				approver: 'Approver',
				observation: 'Comments',
				download: 'Download',
				status: 'Status',
				not_sended: 'Not Sent',
				pending: 'Pending',
				viewed: 'Viewed',
				approved: 'Approved',
				repproved: 'Rejected',
				cancelled: 'Cancelled',
			},
			doc_table: {
				container: 'Container',
				cargo: 'Load Number',
				po: 'PO',
				plton: 'Volume',
				sequence: 'Sequence',
				courier: 'Courier Company',
				awb: 'AWB',
				shipping: 'Sent Date',
				destiny: 'Destination',
				delivery: 'Delivery Date',
				status: 'Status',
				notSent: 'Not Sent',
				delivered: 'Delivered',
				inTransit: 'In transit',
				bank: 'Bank',
				client: 'Client',
				invalidDate: 'Invalid Date',
			},
			reproval_modal: {
				title: 'Document (Specific)',
				subtitle: 'Please Insert A Comment About Your Disapproval',
				comment: 'Comments',
				approved: 'Document approved successfully!',
				repproved: 'Document rejected successfully!',
			},
		},
		doc_instruction: {
			filter: {
				pod: 'POD',
				acro_description: 'Item Description',
				contract: 'Contract / Sequence',
			},
			steps: {
				title: 'Instruction Name',
				selectTheContract:
					'Select The Contract You Wish To Select/Register The Documentary Instructions',
				selectDocumentaryInstruction:
					'Choose The Documentary Instruction You Want To Indicate Or Register A New One.',
				confirmTheData: 'Confirm The Details Of Your Documentary Instructions',
				enterDestination: 'Inform The Destination (Complete Address) Of The Original Documents',
				notFound: 'Not Found',
				step_one_description:
					'Select The Contract You Wish To Select/Register The Documentary Instructions',
				step_two_description:
					'Choose The Documentary Instruction You Want To Indicate Or Register A New One.',
				step_three_description: 'Confirm The Details Of Your Documentary Instructions',
				step_four_description:
					'Inform The Destination (Complete Address) Of The Original Documents',
				step_by_step: 'Step by Step',
				mobile_step: 'Step {step} of 4',
			},
			doc_row: {
				code: 'Code',
				name: 'Name',
				update_date: 'Last Update',
				contract: 'Contract',
				customer: 'Customer',
			},
			doc_table: {
				sequence: 'Sequence',
				pod: 'POD',
				acro: 'Item Description',
			},
			inactive_templates_title: 'Inactive Templates',
			select_doc_instruction: 'Select documentary instruction',
			selected_contracts_title: 'Select Contracts / Sequence(S)',
			consignee_modal: {
				header: 'Consignee / Notify',
				name: 'Name',
				email: 'E-mail',
				phone: 'Telephone',
				address: 'Address',
				country: 'Country',
				state: 'State',
				city: 'City',
				zipcode: 'ZIP Code',
				tax: 'Tax ID',
				summary: 'Summary',
				characters: 'Characters',
			},
			documents_package: {
				bank: 'Bank Name',
				otherRecipient: 'Other Receiver',
				address: 'Address',
				postCode: 'Zip Code',
				fax: 'Telephone ',
				taxID: 'TAX ID',
				receiverName: 'Contact Person',
			},
			modal_add_bank: {
				titleEdit: 'Edit bank',
				title: 'Add New Bank',
				text: "Please Contact Seara'S Commercial Assistant And Inform The Complete Information Of The Bank You Want To Insert.",
			},
			confirm_modal: {
				warning:
					'Warning! You Are Up To Swap The Customer With A Documentary Instruction Partially Filled, In Case You Continue Your Changes Will Be Lost. Do You Want To Continue?',
			},
			modal_no_data: {
				title: 'Bank',
				text: "No banks were found for your registration. Please contact Seara's Customer Experience team.",
			},
		},
		templateData: {
			inputs: {
				default: 'Standard',
				perOrder: 'Template',
				template_title: 'Template Name',
			},
			items: {
				invoice: 'Invoice',
				packing: 'Packing List Consolidate',
				billOfLanding: 'Bill of Landing',
				healthCertificate: 'Health Certificate',
				certificateOfOrigin: 'Certificate of Origin',
				consignee: 'Consignee',
				notify: 'Notify',
				buyer: 'Buyer',
				adress: 'Address',
				observations: 'Additional Information',
				secondNotify: 'Second Notify',
				freigtClause: 'Freight Clause',
				telexRelease: 'Telex Release',
				responsable: 'Logistic Operator',
				countriesOfTransit: 'In Transit To',
				importer: 'Importer',
				behalf: 'On behalf of',
				both: 'Both',
			},
			actions: {
				edit: 'Edit',
			},
			requests_messages: {
				template_status_change: 'Template Updated Successfully',
				template_error: 'At Least One Option Must Be Marked For Bill Of Lading',
				template_send: 'Documentary Instruction Successfully Sent!',
			},
		},
	},
};
