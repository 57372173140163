<template>
	<div id="login" class="login">
		<div class="lang">
			<LangSelector :isLogin="true" ref="langSelector" />
		</div>
		<div class="background">
			<img class="background__img" :src="imgPath" />
		</div>
		<div class="container">
			<div class="logo" v-if="isWeb || (!isWeb && !isChangePassword)">
				<CompanyLogo class="logo__img" />
				<p class="logo__text">
					Customer <span class="logo__text-break">service</span>
					<span class="logo__text-break">portal</span>
				</p>
			</div>
			<div class="back-btn" v-if="!isWeb && isChangePassword" @click="viewChangePassword()">
				<BackArrow />
			</div>
			<LoginForm
				@viewChangePassword="viewChangePassword"
				@handleTwoWay="handleTwoWay"
				v-if="!isChangePassword && !isTwoWay"
			/>
			<TwoWayForm
				:isWeb="isWeb"
				:hashCode="hashCode"
				@handleTwoWay="handleTwoWay"
				v-else-if="!isChangePassword && isTwoWay"
			/>
			<RecoverForm
				@viewChangePassword="viewChangePassword"
				@showMessageScreen="showMessageScreen"
				v-else-if="isChangePassword && !isSendingEmail && !isTwoWay"
			/>
			<SuccessMessage :text="$t('modules.login.email_sended')" v-else />
		</div>
		<Version />
	</div>
</template>

<script>
import LoginForm from '@/components/modules/Login/LoginForm/LoginForm.vue';
import TwoWayForm from '@/components/modules/Login/TwoWayForm/TwoWayForm.vue';
import RecoverForm from '@/components/modules/Login/RecoverForm/RecoverForm.vue';
import BackArrow from '@/components/shared/Icons/BackArrow.vue';
import CompanyLogo from '@/components/shared/Icons/CompanyLogo.vue';
import LangSelector from '@/components/shared/LangSelector/LangSelector.vue';
import SuccessMessage from '@/components/shared/SuccessMessage/SuccessMessage.vue';
import Version from '@/components/shared/Version/Version.vue';

export default {
	name: 'Login',
	components: {
		LangSelector,
		LoginForm,
		TwoWayForm,
		RecoverForm,
		SuccessMessage,
		Version,
		CompanyLogo,
		BackArrow,
	},
	data: () => ({
		windowWidth: window.innerWidth,
		isChangePassword: false,
		isSendingEmail: false,
		isTwoWay: false,
		hashCode: '',
	}),
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		viewChangePassword() {
			this.isChangePassword = !this.isChangePassword;
		},
		handleTwoWay(hashCode) {
			if (hashCode) {
				this.hashCode = hashCode;
			}

			this.isTwoWay = !this.isTwoWay;
		},
		showMessageScreen() {
			this.isSendingEmail = !this.isSendingEmail;

			setTimeout(() => {
				this.isSendingEmail = !this.isSendingEmail;
				this.isChangePassword = !this.isChangePassword;
			}, 3500);
		},
	},
	computed: {
		isWeb() {
			return this.windowWidth > 992;
		},
		imgPath() {
			const web = require('@/assets/home.png');
			const mobile = require('@/assets/home-mobile.png');

			return this.isWeb ? web : mobile;
		},
	},
};
</script>

<style lang="scss">
@import 'Login.scss';
</style>
